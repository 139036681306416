
/*
 *   File : manage-category.js
 *   Author URI : https://evoqins.com
 *   Description : Modal component to create and edit categories in the application.
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import { Icon } from "../Icon";
import { CustomFileInput, CustomTextArea, CustomTextInput1 } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Styles
import style from "../../Styles/Components/manage-category.module.scss";

// Services
import APIService from "../../Services/api-service";
import { _getFilePath } from "../../Helper/api";
import { _uploadDocumentToS3 } from "../../Services/aws-service";

const ManageCategory = (props) => {

    const [userIcon, setUserIcon] = useState("");
    const [userIconLoader, setUserIconLoader] = useState(false);
    const [userIconError, setUserIconError] = useState("");

    const [userCategory, setUserCategory] = useState("");
    const [userCategoryError, setUserCategoryError] = useState("");

    const [moreDetails, setMoreDetails] = useState("");
    const [moreDetailsError, setMoreDetailsError] = useState("");

    const [investorId, setInvestorId] = useState(null);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        if (props.data !== null) {
            setUserIcon(props.data.logo);
            setInvestorId(props.data.investor_type_id);
            setUserCategory(props.data.investor_type);
            setMoreDetails(props.data.description);
        }
    }, [props]);

    useEffect(() => {
        var my_modal = document.getElementById("add-user-category-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setUserIcon("");
            setUserCategory("");
            setMoreDetails("");
            setUserIconError("");
            setUserCategoryError("");
            setMoreDetailsError("");
        });
        //eslint-disable-next-line
    }, [props.close]);



    function _handleCategoryInput(value) {
        setUserCategory(value);
        setUserCategoryError("");
    }

    function _handleDetails(value) {
        setMoreDetails(value);
        setMoreDetailsError("");
    }

    function _handleSizeError() {
        setUserIconError("File size exceeds 2MB");
    }


    function _handleValidation() {
        let valid = true;
        if (userIcon.length === 0) {
            setUserIconError("Upload icon");
            valid = false;
        }
        if (userCategory.length === 0) {
            setUserCategoryError("Enter category");
            valid = false;
        }
        if (moreDetails.length === 0) {
            setMoreDetailsError("Enter details");
            valid = false;
        }
        if (valid === true) {
            _addInvestorType();
        }
    }


    // API - file upload
    const _handleFileUpload = async (file) => {
        setUserIconLoader(true);
        setUserIconError("");
        const request = {
            file: file.name,
            is_public: true
        }

        await _getFilePath(request).then(async (response) => {
            toast.dismiss();
            await _uploadDocumentToS3(response.data, file).then((result) => {
                setTimeout(() => {
                    setUserIcon(response.data.view_info);
                }, 100);
                setUserIconLoader(false);
            }).catch((error) => {
                toast.error(error, {
                    type: "error"
                });
                setUserIconLoader(false);
            });
        });
    }

    // API - manage investor type

    const _addInvestorType = () => {
        setApiLoader(true);
        const url = "/investor-type/upsert";

        const request = {
            "investor_type_id": investorId,
            "investor_type": userCategory,
            "logo": userIcon,
            "description": moreDetails
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success('Investor type added', {
                    type: 'success'
                });
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateList()
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_modal}`}
            id="add-user-category-modal"
            tabIndex="-1"
            aria-labelledby="add-user-category-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-24px-lr padding-32px-tb ${style.e_content} `}>
                    <div className="d-flex align-items-center justify-content-between mb-4 px-2">
                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-24px mb-0">{props.data !== null ? "Update" : "Add new"} user category</p>
                        <Icon icon="close"
                            size={18}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer' />
                    </div>
                    <div className={`${style.e_modal_scroll} px-2`}>

                        <div className="row">
                            <div className="col-8 mb-4">
                                <CustomFileInput shape="square"
                                    label="Upload user category icon"
                                    postfix={true}
                                    file={userIcon}
                                    accept={["JPG", "PNG", "JPEG"]}
                                    error={userIconError}
                                    loader={userIconLoader}
                                    sizeError={_handleSizeError}
                                    onSelectFile={(file) => _handleFileUpload(file)}
                                    clearFile={() => setUserIcon("")} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <CustomTextInput1 label="User category"
                                    type="text"
                                    postfix={"*"}
                                    error={userCategoryError}
                                    value={userCategory}
                                    placeHolder="Category"
                                    handleChange={_handleCategoryInput} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 margin-32px-bottom">
                                <CustomTextArea label="More details"
                                    placeHolder="More details"
                                    id="Details"
                                    postfix="*"
                                    value={moreDetails}
                                    error={moreDetailsError}
                                    handleChange={_handleDetails} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-16px">
                        <SecondaryButton label="Cancel"
                            cancel="modal"
                            className="padding-14px-tb padding-50px-lr" />
                        <div className="col-4">

                            <PrimaryButton label={props.data !== null ? "Update" : "Add"}
                                disabled={apiLoader}
                                loader={apiLoader}
                                className="padding-14px-tb w-100"
                                handleClick={_handleValidation} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageCategory