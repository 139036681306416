
import Color from '../../src/Styles/color.module.scss';

const SEGMENT_COLORS = ['#4DB24C', '#FFCC17', '#FFA619', '#FF625F'];

const BADGE_COLORS = {
    green: Color.turqoise_green,
    orange: Color.burnt_orange,
    blue: Color.navy_blue,
    red: Color.crimpson_red,
    magenta: Color.magenta
}

const Config = {
    SEGMENT_COLORS: SEGMENT_COLORS,
    BADGE_COLORS: BADGE_COLORS
}

export default Config



