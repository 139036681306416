/*
 *   File : faq.js
 *   Author URI : www.evoqins.com
 *   Description : Container page to list FAQs
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// Components
import { CustomTitle } from "../../../../Components/Title";
import { CustomTabBar } from "../../../../Components/Tab";
import { PrimaryButton, TextButton } from "../../../../Components/Buttons";
import { EmptyScreen } from "../../../../Components/Others";
import { ConfirmDeleteModal, ManageFaq } from "../../../../Components/Modal";
import { CustomSelect } from "../../../../Components/FormElements";

// Services
import APIService from "../../../../Services/api-service";


const TAB_OPTIONS = [
    // {
    //     label: "Banner images",
    //     id: 1
    // },
    {
        label: "FAQ",
        id: 2
    },
    {
        label: "Terms & conditions",
        id: 3
    },
    {
        label: "Privacy policy",
        id: 4
    },
    {
        label: "Disclaimer",
        id: 5
    },
    {
        label: "Value pair setting",
        id: 6
    },
    {
        label: "User category settings",
        id: 7
    }
]



const FAQ = () => {

    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[0].id);
    const [activeIndex, setActiveIndex] = useState(0);

    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);

    const [faqList, setFaqList] = useState([]);

    const [addFaq, setAddFaq] = useState(false);
    const [faqData, setFaqData] = useState(null);

    const [faqId, setFaqId] = useState(null);
    const [filterType, setFilterType] = useState(null);
    const [faqFilters, setFaqFilters] = useState([]);
    const [faqTypes, setFaqTypes] = useState([]);


    useEffect(() => {

        _getFaqTypes();
    }, []);

    useEffect(() => {
        if (filterType !== null) {
            _listFaq();
        }
        // eslint-disable-next-line
    }, [filterType])

    useEffect(() => {
        if (addFaq === true) {
            const modal = new Modal(document.getElementById("manage-faq"));
            modal.show();
        }
    }, [addFaq]);

    useEffect(() => {
        if (faqId !== null) {
            const modal = new Modal(document.getElementById("confirm-delete"));
            modal.show();
        }
    }, [faqId]);

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        switch (tab_index) {
            case 1:
                navigate('/app-settings');
                break;
            case 2:
                navigate('/app-settings');
                break;
            case 3:
                navigate('/app-settings/terms-conditions');
                break;
            case 4:
                navigate('/app-settings/privacy-policy');
                break;
            case 5:
                navigate('/app-settings/disclaimer');
                break;
            case 6:
                navigate('/app-settings/value-pair-setting');
                break;
            case 7:
                navigate('/app-settings/user-category-setting');
                break;

            default:
                break;
        }
    }

    function _handleAddFaq(status, detail) {
        setAddFaq(status);
        setFaqData(detail)
    }

    function _handleAccordionClick(index) {
        setActiveIndex(index);
    }

    function _openDeleteModal(id) {
        setFaqId(id);
    }

    function _handleFilterType(select_value) {
        setFilterType(select_value);
    }

    function _handleDelete() {
        const faq_list = [...faqList]
        const index = faq_list.findIndex(item => item.nudge_id === faqId);
        faq_list.splice(index, 1)
        setFaqList(faq_list);
        setFaqId(null);
    }

    // API - list faq
    const _listFaq = () => {
        const url = "/content/list-faq";

        const request = {
            "faq_type_id": filterType.value
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setFaqList(response.data);
            } else {
                setFaqList([]);
            }
            setPageLoader(false);
        });
    }

    // API -list faq types
    const _getFaqTypes = () => {
        const url = "/content/faq-filter_types";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFaqTypes(response.data);
                const faq_filters = []
                faq_filters.push({
                    label: "All",
                    value: null
                })
                response.data.map((item) => {
                    return (
                        faq_filters.push(item)
                    )
                });
                setFilterType(faq_filters[0]);
                setFaqFilters(faq_filters);
            }
        })
    }
    // API -delete FAq
    const _deleteFaq = async () => {
        let status = false
        setApiLoader(true);
        const url = "/content/delete-faq";
        const request = {
            faq_id: faqId
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Faq deleted", {
                    type: 'success'
                })
                status = true;
                _handleDelete()
            } else {
                status = false;
            }
            setApiLoader(false);
        })
        return status
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="App Settings" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-40px-tb ">
                    <div className="row padding-32px-lr">
                        <div className="col-12">
                            <CustomTabBar data={TAB_OPTIONS}
                                selectedTab={tabIndex}
                                onSelectTab={_handleTab} />
                        </div>
                        <div className="col-12 padding-32px-top margin-22px-bottom">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-0">
                                    Question & answers
                                </h4>
                                <PrimaryButton
                                    label="Add FAQ"
                                    className="padding-10px-tb px-4"
                                    handleClick={() => _handleAddFaq(true, null)} />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Faq type</label>
                            <CustomSelect type={1}
                                options={faqFilters}
                                value={filterType}
                                placeholder="All"
                                selectChange={_handleFilterType} />
                        </div>
                    </div>
                    {
                        pageLoader === true ?
                            <div className="h-80vh d-flex align-items-center justify-content-center">
                                <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                    alt={"loader"}
                                    width={60}
                                    height={60}
                                />
                            </div>
                            :
                            faqList.length === 0 ?
                                <EmptyScreen className="my-5"
                                    title="No faq found"
                                    image={require("../../../../Assets/Images/loader/empty-funds.png")}
                                    description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                :
                                <div class="accordion padding-32px-lr" id="faq-accordian">
                                    <div className="row">
                                        <div className="col-6">
                                            {
                                                //eslint-disable-next-line
                                                faqList.map((item, key) => {
                                                    if (key % 2 === 0) {
                                                        return (
                                                            <div class={`accordion-item  border-0 e-faq-items p-4 mb-2 ${activeIndex === key && "e-bg-pastel-lavender "}`} >
                                                                <h2 class="accordion-header" id={`heading-${key}`}>
                                                                    <button class={`accordion-button collapsed p-0 bg-transparent e-montserrat-medium e-font-14 e-line-height-20 ${activeIndex === key && "mb-3"}`} type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#collapse-${key}`}
                                                                        aria-expanded={activeIndex === key ? true : false}
                                                                        aria-controls={`collapse-${key}`}
                                                                        onClick={() => {
                                                                            if (key !== activeIndex) {
                                                                                _handleAccordionClick(key)
                                                                            } else {
                                                                                _handleAccordionClick(null)
                                                                            }
                                                                        }}>
                                                                        {item.question}
                                                                    </button>
                                                                </h2>
                                                                {activeIndex === key && <div

                                                                >
                                                                    <div class="accordion-body p-0 e-font-14 e-montserrat-regular e-line-height-24 color-dark-gray">
                                                                        {item.answer}
                                                                        <div className="d-flex gap-16px mt-3">
                                                                            <TextButton className="e-font-14 e-line-height-24"
                                                                                name="Edit"
                                                                                hide_arrow={true}
                                                                                onPress={() => _handleAddFaq(true, item)} />
                                                                            <TextButton className="e-font-14 e-line-height-24"
                                                                                name="Delete"
                                                                                hide_arrow={true}
                                                                                onPress={() => _openDeleteModal(item.faq_id)} />
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                //eslint-disable-next-line
                                                faqList.map((item, key) => {
                                                    if (key % 2 !== 0) {
                                                        return (
                                                            <div class={`accordion-item  border-0 e-faq-items p-4 mb-2 ${activeIndex === key && "e-bg-pastel-lavender "}`}>
                                                                <h2 class="accordion-header" id={`heading-${key}`}>
                                                                    <button class={`accordion-button collapsed p-0 bg-transparent e-montserrat-medium e-font-14 e-line-height-20 ${activeIndex === key && "mb-3"}`} type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#collapse-${key}`}
                                                                        aria-expanded={activeIndex === key ? true : false}
                                                                        aria-controls={`collapse-${key}`}
                                                                        onClick={() => {
                                                                            if (key !== activeIndex) {
                                                                                _handleAccordionClick(key)
                                                                            } else {
                                                                                _handleAccordionClick(null)
                                                                            }
                                                                        }}>
                                                                        {item.question}
                                                                    </button>
                                                                </h2>
                                                                {activeIndex === key && <div>
                                                                    <div class="accordion-body p-0 e-font-14 e-montserrat-regular e-line-height-24 color-dark-gray">
                                                                        {item.answer}
                                                                        <div className="d-flex gap-16px mt-3">
                                                                            <TextButton className="e-font-14 e-line-height-24"
                                                                                name="Edit"
                                                                                hide_arrow={true}
                                                                                onPress={() => _handleAddFaq(true, item)} />
                                                                            <TextButton className="e-font-14 e-line-height-24"
                                                                                name="Delete"
                                                                                hide_arrow={true}
                                                                                onPress={() => _openDeleteModal(item.faq_id)} />
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>

                                    </div>

                                </div>
                    }
                </div>

            </div>
            {
                addFaq === true && <ManageFaq data={faqData}
                    closeModal={() => _handleAddFaq(false, null)}
                    faq_types={faqTypes}
                    faqUpdated={() => {
                        _listFaq();
                        _handleAddFaq(false, null);
                        setFilterType({ label: "All", value: null })
                    }} />
            }

            {
                faqId !== null && <ConfirmDeleteModal title="Delete FAQ"
                    content="Are you sure you want to delete this FAQ? This action cannot be undone."
                    closeModal={() => _openDeleteModal(null)}
                    delete={_deleteFaq}
                    loader={apiLoader}
                    updateList={() => {
                        _openDeleteModal(null)
                    }} />
            }


        </Fragment>
    )
}

export default FAQ