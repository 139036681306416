/*
 *   File : goal-investments.js
 *   Author : https://evoqins.com
 *   Description : Investments with goals List
 *   Version : 1.0.0
*/

// Packages
import { useEffect, useState } from "react";

// Custom components
import { DataTableContainer } from "../../../../Components/DataTable";
import { InfoCard } from "../../../../Components/Cards";
import { EmptyScreen } from "../../../../Components/Others";

// Custom styles
import Colors from '../../../../Styles/color.module.scss';

// Services
import APIService from "../../../../Services/api-service";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "18px",
            paddingTop: "0px",
            paddingBottom: '0px',
            minHeight: "32px"
        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};


const GoalInvestments = (props) => {

    const [investmentData, setInvestmentData] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        _getInvestmentData();
        //eslint-disable-next-line
    }, []);

    const GOAL_COLUMNS = [
        {
            name: "Invested funds",
            cell: row => <div className="d-flex align-items-center">
                <img src={row.image}
                    alt={row.scheme_name}
                    width={38}
                    height={38}
                    draggable={false}
                    className="border-radius-8px" />
                <span className="ps-2">{row.scheme_name}</span>
            </div>,
            width: "70%"
        },
        {
            name: "Amount",
            width: "110px",
            cell: row => row.invested_amount_text
        },
        {
            name: "Units",
            cell: row => row.units
        }
    ]

    // API - non investment data
    const _getInvestmentData = () => {
        const url = "/user/investment-with-goals";
        const request = {
            user_id: props.id,
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setInvestmentData(response.data);
            }
            else {
                setInvestmentData([]);
            }
            setLoader(false);
        })
    }

    return (
        <div className="bg-white border-radius-24px padding-32px-tb mt-4">
            <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">
                Investments
            </h4>
            {
                loader === true ?
                    <div className="h-80vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    investmentData.length === 0 ?
                        <EmptyScreen className="my-5"
                            title="No investments found"
                            image={require("../../../../Assets/Images/loader/empty-funds.png")}
                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                        :
                        investmentData.map((item, key) => (
                            <div className="bg-white border-radius-24px padding-32px-tb mt-4"
                                key={key}>
                                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">
                                    {item.name}
                                </h4>

                                <div className="padding-32px-lr">
                                    <div className="row padding-32px-bottom">
                                        <div className="col-lg-3 col-sm-6 col-12 mb-lg-0 mb-3">
                                            <InfoCard type={2}
                                                title="Target amount"
                                                data={item.target_text} />
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 mb-lg-0 mb-3">
                                            <InfoCard type={2}
                                                title="Amount invested"
                                                data={item.invested_amount_text} />
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 mb-lg-0 mb-3">
                                            <InfoCard type={2}
                                                title="Current value"
                                                data={item.current_amount_text} />
                                        </div>
                                        <div className="col-lg-3 col-sm-6 col-12 mb-lg-0 mb-3">
                                            <InfoCard type={2}
                                                title="Total returns"
                                                color={item.returns_amount < 0 ? "color-red" : "color-emerald-green"}
                                                data={item.returns_amount_text} />
                                        </div>
                                    </div>
                                </div>

                                <DataTableContainer data={item.funds}
                                    columns={GOAL_COLUMNS}
                                    customStyles={TABLE_STYLE} />

                            </div>
                        ))
            }
        </div>
    )
}

export default GoalInvestments;