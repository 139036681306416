/*
 *   File : input.js
 *   Author URI : www.evoqins.com
 *   Description : Input
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// packages
import React, { Fragment, useEffect, useState, useRef } from "react";

// Custom components
import Icon from '../Icon/icon';

// Custom styles
import style from "../../Styles/Components/input.module.scss";

const CustomTextInput = React.memo(function CustomTextInput(props) {
    //eslint-disable-next-line
    const [isInputFocused, setInputFocus] = useState(false);
    const inputRef = useRef(null);
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const _handleChange = (event) => {
        let input_value = event.target.value;
        if (props.max && input_value.length > props.max) {
            props.valueChange(input_value.substring(0, props.max));
            setValue(input_value.substring(0, props.max));
        } else {
            props.valueChange(input_value);
            setValue(input_value);
        }
    }

    /* ---- func to be implemented on press of enter ----*/
    const _keyPress = (e) => {
        if (props.type === "number") {
            const key_code = e.keyCode || e.which;
            const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
            const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

            // Add 190 to allowed_key_codes conditionally based on props.decimal
            if (!props.decimal) {
                allowed_key_codes.push(190); // '.' on US keyboards
            }

            if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
                // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
                return null;
            }

            if (!allowed_key_codes.includes(key_code)) {
                e.preventDefault();
            }
            if (props.min === 0) {
                if (e.key === '0' && value.startsWith('0')) {
                    // Prevent entering multiple leading zeros
                    e.preventDefault();
                } else if (props.value === '0' && e.key >= '1' && e.key <= '9') {
                    // If value is "0" and a non-zero number is entered, replace "0" with the new key
                    e.preventDefault();
                    props.onChange(e.key); // Set input to the new key
                }
            } else {
                if (e.key === '0' && props.value === "") {
                    // Prevent '0' as the first input if min isn't 0
                    e.preventDefault();
                }
            }
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        }
    };

    const _handleFocus = () => {
        setInputFocus(true);
    };

    const _handleBlur = () => {
        // setInputFocus(false);
    };

    return (
        <Fragment>
            <div className={
                props.error && props.error.length !== 0 ?
                    `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                    `${props.className} ${style.e_input_wrapper} position-relative w-100`}
                data-disabled={props.disabled}>
                {/* Input box */}
                <input
                    value={value}
                    id={props.label}
                    type={props.type ? props.type : 'text'}
                    className={`${style.e_input} e-montserrat-regular e-font-14 e-line-height-18 w-100 no-outline`}
                    style={{ paddingRight: props.password_icon === true && "48px" }}
                    onChange={_handleChange}
                    placeholder={props.placeholder}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onKeyDown={_keyPress}
                    required
                    ref={inputRef}
                />
                {/* label */}
                {props.label && <label className={`${props.labelClass} e-montserrat-regular e-font-14 cursor-text p-0`}
                    htmlFor={props.label}>
                    {props.label}
                    <span className='color-deep-carmine-pink'>
                        {props.postfix}
                    </span>
                </label>}
                {/* Error text */}
                {
                    props.hide_error_text ?
                        null
                        :
                        props.error &&
                        <div className='position-absolute d-flex align-items-center mt-1 '>
                            <Icon icon="info-circle"
                                size={16}
                                className="me-1" />
                            <p className='color-deep-carmine-pink mb-0 e-font-12 my-0 '>
                                {props.error}
                            </p>
                        </div>
                }
                {
                    value ?
                        <span className={style.e_suffix}>{props.suffix}</span>
                        :
                        null
                }

            </div>
        </Fragment>
    );
});

CustomTextInput.defaultProps = {
    onChange: () => { },
    id: 1,
    value: "",
    error: "",
    is_number: false,
    decimal: false, // add default prop for decimal
    placeholder: "",
    className: "",
    title: "",
    labelClass: 'bg-white',
    disabled: false
}

export default CustomTextInput;
