
/*
 *   File : add-funds.js
 *   Author URI : https://evoqins.com
 *   Description : The modal component used for adding funds.
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect, useState } from "react";
import { toast } from "react-toastify";


// Custom components
import { Icon } from "../Icon";
import { SearchInput } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/add-fund.module.scss";

// Services
import APIService from "../../Services/api-service";

import SearchFund from "../../Assets/Images/Funds/search.png"


const AddFundsModal = (props) => {
    const [search, setSearch] = useState("");
    const [showList, setShowList] = useState(false);
    //eslint-disable-next-line
    const [fundList, setFundList] = useState([]);
    const [fundIds, setFundIds] = useState([]);
    const [loader, setLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("add-funds");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            setShowList(false);
            setFundIds([]);
        });
        //eslint-disable-next-line
    }, [props.closeModal]);


    useEffect(() => {
        _getFunds();
        //eslint-disable-next-line
    }, []);

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _getFunds("");
        }
    }

    function _listFunds() {
        setShowList(true)
    }

    function _handleFunds(id) {
        let funds = [...fundIds]
        const index = funds.findIndex((item) => item === id)
        if (index === -1) {
            funds.push(id)
        } else {
            funds.splice(index, 1)
        }
        setFundIds(funds)
    }

    // search funds
    const _getFunds = (query) => {
        setLoader(true);
        const url = "/model-portfolio/get-fund";
        const request = {
            query: query,
            recommended_fund_query: true
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setFundList(response.data);
                _listFunds();
            } else {
                setFundList([]);
            }
            setLoader(false);
        })
    }

    // add funds
    const _addFund = () => {
        setApiLoader(true);
        const url = "/recomended-funds/add";
        const request = {
            "mf_ids": fundIds
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("Funds added successfully", {
                    type: "success"
                });
                props.updateList();
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        })
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_add_fund}`}
                id="add-funds"
                tabIndex="-1"
                aria-labelledby="add-funds"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Add mutual fund
                            </h5>
                            <Icon icon="close"
                                size={18}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className="padding-32px-top">
                            <div className="row">
                                <div className="col-12 pb-3">
                                    <SearchInput className={`${style.e_search_input} py-2`}
                                        value={search}
                                        valueChange={_handleSearch}
                                        clear={() => {
                                            setSearch("");
                                            _getFunds("");

                                        }}
                                        handleSearch={() => _getFunds(search === "" ? null : search)} />
                                </div>
                                {
                                    loader === true ?
                                        <div className={`${style.e_wrapper} d-flex align-items-center`}>
                                            <img src={require("../../Assets/Images/loader/page-loader.gif")}
                                                alt={"loader"}
                                                width={60}
                                                height={60}
                                                className="mx-auto" />
                                        </div>
                                        :
                                        showList === true ?
                                            fundList.length === 0 ?
                                                <div className={`${style.e_wrapper} d-flex align-items-center`}>
                                                    <div className="col-12 text-center">
                                                        <img src={SearchFund}
                                                            alt="search"
                                                            draggable={false}
                                                            width={160}
                                                            height={160}
                                                            className="my-auto mx-auto" />
                                                        <p className="mb-0 mt-3 color-silver-gray text-center e-font-14 e-line-height-18 e-montserrat-medium">Search for funds , No funds found</p>
                                                    </div>
                                                </div>
                                                :
                                                <div className={`${style.e_wrapper}`}>
                                                    <div className="row">
                                                        {

                                                            fundList.map((item) => {
                                                                return (

                                                                    <div className="col-sm-6 pb-4">
                                                                        <div className="d-flex align-items-center cursor-pointer" onClick={() => _handleFunds(item.value)} >
                                                                            <span>
                                                                                <Icon icon={fundIds.includes(item.value) ? "checked" : "unchecked"} size={20}
                                                                                    className="cursor-pointer"
                                                                                />
                                                                            </span>
                                                                            <div className="d-flex align-items-center">
                                                                                <img src={item.image}
                                                                                    alt={item.fund_name}
                                                                                    width={28}
                                                                                    height={28}
                                                                                    draggable={false}
                                                                                    className="ms-3 me-2 border-radius-8px border-1px border-light-gray object-fit-contain" />
                                                                                <span className="e-montserrat-medium e-font-14 e-line-height-20 color-dark-charcoal">{item.label}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })}
                                                    </div>
                                                </div>
                                            :
                                            <div className={`${style.e_wrapper} d-flex align-items-center`}>
                                                <div className="col-12 text-center">
                                                    <img src={SearchFund}
                                                        alt="search"
                                                        draggable={false}
                                                        width={160}
                                                        height={160}
                                                        className="my-auto mx-auto" />
                                                    <p className="mb-0 mt-3 color-silver-gray text-center e-font-14 e-line-height-18 e-montserrat-medium">Search for funds </p>
                                                </div>
                                            </div>
                                }
                            </div>
                            <div className="d-flex justify-content-end pt-3">
                                <div className="d-flex gap-8px justify-content-end">
                                    <SecondaryButton label="Cancel"
                                        cancel="modal"
                                        className="padding-14px-tb padding-50px-lr" />
                                    <div className="col-8">
                                        <PrimaryButton label="Add"
                                            className="padding-14px-tb w-100"
                                            handleClick={_addFund}
                                            loader={apiLoader}
                                            disabled={fundIds.length === 0 ? true : apiLoader} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment >
    )
}

export default memo(AddFundsModal);