
/*
 *   File : manage-admin.js
 *   Author URI : https://evoqins.com
 *   Description : Manage admin modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages 
import { memo, useEffect, useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { CustomSelect, CustomTextInput1 } from "../FormElements"
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

// helper
import { _emailValidator } from "../../Helper/helper";
import APIService from "../../Services/api-service";
import { toast } from "react-toastify";


const ManageAdminModal = (props) => {

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [userType, setUserType] = useState(null);
    const [userTypeError, setUserTypeError] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("manage-admin");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            _clearFields();
        });
        //eslint-disable-next-line
    }, []);

    function _handleName(input_value) {
        setName(input_value);
        setNameError("");
    }

    function _handleUserType(select_value) {
        setUserType(select_value);
        setUserTypeError("");
    }

    function _handleEmail(input_value) {
        setEmail(input_value);
        setEmailError("");
    }

    function _handleNumber(input_value) {
        if (input_value.length <= 10) {
            setPhoneNumber(input_value);
        }
        setPhoneNumberError("");
    }

    function _handleSubmitForm() {
        let valid = true;
        if (name === "") {
            valid = false;
            setNameError("Name is required");
        }
        if (userType === null) {
            valid = false;
            setUserTypeError("User type is required");
        }

        if (email === "") {
            valid = false;
            setEmailError("Email is required");
        }
        if (email !== "" && _emailValidator(email) === false) {
            valid = false;
            setEmailError("Invalid email");
        }

        if (phoneNumber === "") {
            valid = false;
            setPhoneNumberError("Phone number is required");
        }
        if (valid === true) {
            _inviteNewAdmin();
        }
    }

    function _clearFields() {
        setEmail("");
        setEmailError("");
        setUserType(null);
        setUserTypeError("");
        setEmail("");
        setEmailError("");
        setPhoneNumber("");
        setPhoneNumberError("");
    }


    // API - invite admin
    const _inviteNewAdmin = () => {
        setApiLoader(true);
        const url = "/sub-admin/invite";
        const request = {
            "admin_type": userType.value,
            "username": name,
            "email": email,
            "mobile": phoneNumber
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                toast.success("Invitation sent successfully", {
                    type: 'success'
                });
                _clearFields();
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateList();
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_modal_wrapper}`}
            id="manage-admin"
            tabIndex="-1"
            aria-labelledby="manage-admin"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                            Create admin
                        </h5>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer' />
                        <span id="close" data-bs-dismiss="modal" className="d-none"></span>
                    </div>
                    <div className="row  padding-32px-top">
                        <div className="col-6 pb-4">
                            <CustomTextInput1 label="Name"
                                type="text"
                                postfix={"*"}
                                error={nameError}
                                value={name}
                                placeHolder="Name"
                                handleChange={_handleName} />
                        </div>
                        <div className="col-6 pb-4">
                            <CustomSelect label="Admin type"
                                type={3}
                                postFix={true}
                                options={props.admin_types}
                                error={userTypeError}
                                value={userType}
                                placeholder="Select admin type"
                                selectChange={_handleUserType} />
                        </div>
                        <div className="col-6">
                            <CustomTextInput1 label="Email"
                                type="email"
                                postfix={"*"}
                                error={emailError}
                                value={email}
                                placeHolder="Email"
                                handleChange={_handleEmail} />
                        </div>
                        <div className="col-6">
                            <CustomTextInput1 label="Phone number"
                                type="number"
                                postfix={"*"}
                                is_phone={true}
                                error={phoneNumberError}
                                value={phoneNumber}
                                placeHolder="Phone number"
                                handleChange={_handleNumber} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end margin-40px-top gap-8px">
                        <SecondaryButton label="Cancel" className="padding-14px-tb padding-50px-lr"
                            cancel="modal" />
                        <div className="col-4">

                            <PrimaryButton label={"Send invitation"}
                                className="padding-14px-tb w-100"
                                disabled={apiLoader}
                                loader={apiLoader}
                                handleClick={_handleSubmitForm} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(ManageAdminModal);