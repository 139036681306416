/*
 *   File : edit-profile.js
 *   Author URI : https://evoqins.com
 *   Description : Edit phone and email modal 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomTextInput1 } from "../FormElements";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

// Helper
import { _emailValidator } from "../../Helper/helper";
import APIService from "../../Services/api-service";
import { toast } from "react-toastify";


const EditProfileModal = (props) => {
    const [email, setEmail] = useState(props.email);
    const [emailError, setEmailError] = useState("");

    const [phoneNumber, setPhoneNumber] = useState(props.phone);
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("edit-profile");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    function _handleEmail(input_value) {
        setEmail(input_value);
        setEmailError("");
    }

    function _handleNumber(input_value) {
        if (input_value.length <= 10) {
            setPhoneNumber(input_value);
        }
        setPhoneNumberError("");
    }

    function _handleSubmitForm() {
        let valid = true;

        if (props.type === 1) {
            if (email === "") {
                valid = false;
                setEmailError("Email is required");
            }
            if (email !== "" && _emailValidator(email) === false) {
                valid = false;
                setEmailError("Invalid email");
            }
        } else {
            if (phoneNumber === "") {
                valid = false;
                setPhoneNumberError("Phone number is required");
            }
        }
        if (valid === true) {
            _updateDetails()
        }
    }


    // API :: Update admin profile details
    const _updateDetails = () => {
        setApiLoader(true);
        const url = "/profile/update";

        const request = {
            "email": email,
            "username": props.userName,
            "mobile": phoneNumber
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Profile updated successfully", {
                    type: "success"
                });
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateDetails();
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }
    return (
        <div className={`modal fade ${style.e_modal_wrapper}`}
            id="edit-profile"
            tabIndex="-1"
            aria-labelledby="edit-profile"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                            {props.type === 1 ? "Edit registered email" : "Edit contact number"}
                        </h5>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer' />
                        <span id="close" data-bs-dismiss="modal" className="d-none"></span>
                    </div>
                    <div className="row padding-32px-top">

                        {
                            props.type === 1 ?
                                <div className="col-12">
                                    <CustomTextInput1 label="Edit email"
                                        type="email"
                                        postfix={"*"}
                                        error={emailError}
                                        value={email}
                                        placeHolder="Email"
                                        handleChange={_handleEmail}
                                        onSubmit={_handleSubmitForm} />
                                </div>
                                :
                                <div className="col-12">
                                    <CustomTextInput1 label="Edit contact no."
                                        type="number"
                                        postfix={"*"}
                                        is_phone={true}
                                        error={phoneNumberError}
                                        value={phoneNumber}
                                        placeHolder="Phone number"
                                        handleChange={_handleNumber}
                                        onSubmit={_handleSubmitForm} />
                                </div>
                        }

                    </div>
                    <div className="d-flex justify-content-end margin-40px-top gap-8px">
                        <SecondaryButton label="Cancel"
                            className="padding-14px-tb padding-50px-lr"
                            cancel="modal" />
                        <PrimaryButton label={"Send invitation"}
                            className="padding-14px-tb px-4 w-30"
                            disabled={apiLoader}
                            loader={apiLoader}
                            handleClick={_handleSubmitForm} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfileModal;