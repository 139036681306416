/*
 *   File : stp.js
 *   Author : https://evoqins.com
 *   Description : STP List
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";

// Custom components
import { Badge } from "../../../../Components/Badge";
import { DataTableContainer } from "../../../../Components/DataTable";
import { EmptyScreen } from "../../../../Components/Others";
// Custom styles
import Colors from '../../../../Styles/color.module.scss';
import Config from "../../../../Helper/config";
import APIService from "../../../../Services/api-service";


// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "18px",
            paddingTop: "0px",
            paddingBottom: '0px',
            minHeight: "32px"
        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};


const StpList = (props) => {

    const [pageLoader, setPageLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [stpList, setStpList] = useState([]);
    const [switchData, setSwitchData] = useState({});

    useEffect(() => {
        _stpList();
        //eslint-disable-next-line
    }, [currentPage]);

    function _handlePageChange(page) {
        setCurrentPage(page);
        setPageLoader(true);
        window.scrollTo(0, 0);
    };

    const SWP_COLUMNS = [
        {
            name: "Fund",
            cell: row => <div className="d-flex align-items-center">
                <span >{row.name}</span>
            </div>,
            width: "200px"
        },
        {
            name: "Switch Out",
            cell: row => <div className="d-inline-block">
                {row.switch_out.name}  (<span className="e-montserrat-semi-bold e-font-14">Units: {row.switch_out.units}</span>)
            </div >,
            width: "240px"
        },
        {
            name: "Switch In",
            cell: row => <div className="d-inline-block">
                {row.switch_in.name}  (<span className="e-montserrat-semi-bold e-font-14">Units: {row.switch_in.units}</span>)
            </div >,
            width: "240px"
        },

        {
            name: "Order no",
            width: "140px",
            cell: row => row.order_no
        },
        {
            name: "Order date",
            width: "140px",
            cell: row => row.order_date
        },
        {
            name: "Investment type",
            width: "140px",
            cell: row => row.investment_type_text
        },
        {
            name: "Amount",
            width: "110px",
            cell: row => row.amount_text
        },
        {
            name: "Order status",
            width: "140px",
            cell: row => <Badge status={row.order_status} color={row.order_status === "Successful" ? Config.BADGE_COLORS.green : row.order_status === "Pending" ? Config.BADGE_COLORS.red : Config.BADGE_COLORS.orange} />
        },
    ]

    // API - SIP list 
    const _stpList = () => {
        const url = "/user/stp"

        const request = {
            customer_id: props.id,
            page_num: currentPage,
            page_size: 10,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setStpList(response.data.orders);
                setSwitchData(response.data);
            } else {
                setStpList([]);
            }
            setPageLoader(false);
        })
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-24px padding-32px-tb">
                <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-4 padding-32px-lr">
                    STP
                </h4>
                {
                    pageLoader === true ?
                        <div className="h-80vh d-flex align-items-center justify-content-center">
                            <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                alt={"loader"}
                                width={60}
                                height={60}
                            />
                        </div>
                        :
                        stpList.length === 0 ?
                            <EmptyScreen className="my-5"
                                title="No STP data found"
                                image={require("../../../../Assets/Images/loader/empty-funds.png")}
                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                            :
                            <div className="pt-4 position-relative">
                                <DataTableContainer data={stpList}
                                    columns={SWP_COLUMNS}
                                    customStyles={TABLE_STYLE}
                                    pagination={true}
                                    paginationServer={true}
                                    perPage={12}
                                    defaultSortAsc={false}
                                    onChangePage={_handlePageChange}
                                    paginationTotalRows={switchData.total_count}
                                    paginationDefaultPage={switchData.current_page}
                                    paginationComponentOptions={{ noRowsPerPage: true }} />
                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-32px-lr position-absolute color-jett-black bottom-10px">Showing {switchData.record} of {switchData.total_count} entries</span>
                            </div>
                }
            </div>
        </Fragment>
    )
}

export default StpList;