/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Nudges are in-app notifications that the admin can trigger.
 *   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// Custom components
import { DataTableContainer } from "../../../Components/DataTable";
import { EmptyScreen } from "../../../Components/Others";
import { PrimaryButton, TextButton } from "../../../Components/Buttons";
import { DeleteConfirmationModal, NudePreviewModal } from "../../../Components/Modal";

// Services, Helper
import APIService from "../../../Services/api-service";

// Styles
import Colors from "../../../Styles/color.module.scss";

// Helper
import { _formatTime } from "../../../Helper/helper";


const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "18px",
            paddingTop: "0px",
            paddingBottom: '0px',
            minHeight: "32px"
        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px"
        }
    }
};



const NudgeList = () => {

    const navigate = useNavigate();
    const [deleteNotification, setDeleteNotification] = useState(null);
    const [viewNotification, setViewNotification] = useState(null);

    const [pageLoader, setPageLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);

    const [nudgeList, setNudgeList] = useState([]);

    useEffect(() => {
        _getNudgeList();
    }, []);

    useEffect(() => {
        if (deleteNotification !== null) {
            const modal = new Modal(document.getElementById("delete-modal"), {});
            modal.show();
        }
    }, [deleteNotification]);

    useEffect(() => {
        if (viewNotification !== null) {
            const modal = new Modal(document.getElementById("nudge-preview"), {});
            modal.show();
        }
    }, [viewNotification]);

    function _openDeleteModal(row) {
        setDeleteNotification(row)
    }

    // function _viewCampaign(row) {
    //     setViewNotification(row)
    // }

    function _handleEdit(row) {
        navigate("manage-nudge", {
            state: {
                data: row,
            }
        });
    }

    // User columns
    const NUDGE_COLUMNS = [
        {
            name: "Nudge title",
            selector: row => row.title,
            width: '400px'
        },
        {
            name: "User category",
            selector: row => row.user_category_text,
            width: '250px'
        },

        {
            name: "Send date & time",
            width: "200px",
            cell: row => <p className="e-montserrat-medium e-font-14 e-line-height-24 mb-0 color-dark-charcoal">{row.start_date}, {row.start_time ? _formatTime(row.start_time) : ""}</p>
        },
        {
            name: '',
            sortable: false,
            width: "180px",
            cell: row => <div className="d-flex gap-16px">
                <TextButton
                    className="e-font-14 e-line-height-24" name="Edit" hide_arrow={true}
                    onPress={() => _handleEdit(row)} />
                <TextButton
                    className="e-font-14 e-line-height-24" name="Delete" hide_arrow={true}
                    onPress={() => _openDeleteModal(row)} />
                {/* <TextButton
                    className="e-font-14 e-line-height-24" name="View" hide_arrow={true}
                    onPress={() => _viewCampaign(row)} /> */}

            </div>
        },

    ]

    const _handleCreateNotification = () => {
        navigate("manage-nudge")
    }


    function _handleDelete() {
        const nudge_list = [...nudgeList]
        const index = nudge_list.findIndex(item => item.nudge_id === deleteNotification.nudge_id);
        nudge_list.splice(index, 1)
        setNudgeList(nudge_list);
        setDeleteNotification(null);
    }

    function _handleClose() {
        setDeleteNotification(null);
        setViewNotification(null);
    }

    // API - list nudges
    const _getNudgeList = () => {
        const url = "/nudge/list";
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setNudgeList(response.data);
            } else {
                setNudgeList([]);
            }
            setPageLoader(false);
        })
    }


    // API - delete nudge
    const _deleteNudge = async () => {

        let status = false
        setApiLoader(true);

        const url = "/nudge/delete"
        const request = {
            nudge_id: deleteNotification.nudge_id
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Nudge deleted successfully", {
                    type: 'success'
                });
                status = true;
                _handleDelete();

            } else {
                toast.error(response.message, {
                    type: 'error'
                });
                status = false;
            }
            setApiLoader(false);
        })
        return status
    }



    return (
        <Fragment>

            <div className="px-4 mb-3">
                <div className="d-flex align-items-center justify-content-between flex-wrap gap-24px">
                    <h4 className='mb-0 e-montserrat-semi-bold e-font-16 e-line-height-24'>
                        Nudge list
                    </h4>
                    <div className="d-flex gap-32px align-items-center justify-content-end">
                        <PrimaryButton label="Create nudge"
                            className="padding-10px-tb padding-20px-lr"
                            handleClick={_handleCreateNotification} />
                    </div>

                </div>
            </div>

            {
                pageLoader === true ?
                    <div className="min-h-100vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    nudgeList.length === 0 ?
                        <EmptyScreen className="my-5"
                            title="No nudges found"
                            image={require("../../../Assets/Images/loader/empty-funds.png")}
                            description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                        :
                        <div className="position-relative">

                            <DataTableContainer
                                columns={NUDGE_COLUMNS}
                                data={nudgeList}
                                selectableRows={false}
                                pagination={false}
                                customStyles={TABLE_STYLE} />

                        </div>}


            {
                deleteNotification !== null &&
                <DeleteConfirmationModal title="Delete nudge"
                    description="Are you sure you want to delete this nudge? This action cannot be undone."
                    close={_handleClose}
                    delete={_deleteNudge}
                    loader={apiLoader}
                    updateList={() => {
                        _handleClose();
                    }} />
            }

            {
                viewNotification !== null &&
                <NudePreviewModal data={viewNotification}
                    close={_handleClose} />
            }

        </Fragment >
    )
}
export default NudgeList;