/*
 *   File : manage-resource.js
 *   Author : https://evoqins.com
 *   Description : Container screen to add or edit resources.
 *   Version : 1.0.0
*/

// Packages
import { Fragment, memo, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// Components
import { CustomFileInput, CustomSelect, CustomTextInput1, CustomWysiwygEditor } from "../../../Components/FormElements";
import { Breadcrumb } from "../../../Components/Others";
import { PrimaryButton, SecondaryButton } from "../../../Components/Buttons";
import { ResourcePublishLater } from "../../../Components/Modal";

// Services,helper
import { _uploadDocumentToS3 } from "../../../Services/aws-service";
import { _getFilePath } from "../../../Helper/api";
import APIService from "../../../Services/api-service";

const initial_data = {
    resourceId: null,
    title: "",
    titleError: "",
    category: null,
    categoryError: "",
    resourceType: null,
    resourceTypeError: "",
    resourceUrl: "",
    resourceUrlError: "",
    bannerUrl: "",
    bannerUrlError: "",
    thumbnailUrl: "",
    thumbnailUrlError: "",
    editorState: "",
    editorStateError: "",
    scheduleDate: "DD-MM-YYYY",
    scheduleTime: null
};

const ManageResource = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const titleRef = useRef(false);
    const categoryRef = useRef(false);
    const resourceTypeRef = useRef(false);
    const bannerImageRef = useRef(false);
    const videoRef = useRef(false);
    const contentRef = useRef(false);

    const [resourceDetail, setResourceDetail] = useState(initial_data);
    const [loader, setLoader] = useState(true);
    const [videoLoader, setVideoLoader] = useState(false);
    const [bannerLoader, setBannerLoader] = useState(false);
    const [thumbnailLoader, setThumbnailLoader] = useState(false);
    const [resourceOptions, setResourceOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [showScheduleModal, setShowScheduleModal] = useState(false);

    useEffect(() => {
        _getFilterData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showScheduleModal === true) {
            const modal = new Modal(document.getElementById("resource-publish-later"));
            modal.show()
        }
    }, [showScheduleModal])

    const _handleNavigate = () => {
        navigate(-1);
    }

    const _handleChange = (property, value) => {
        setResourceDetail((prevState) => ({
            ...prevState,
            [property]: value,
            [property + "Error"]: "",
        }));
    };

    // validation before submitting
    const _handlePublish = (status) => {

        let valid = true;

        if (resourceDetail.editorState.length === 0) {
            contentRef.current.scrollIntoView({ block: 'start' });
            _handleErrorUpdate("editorStateError", "Enter resource content");
            valid = false;
        }
        if (resourceDetail.resourceType && resourceDetail.resourceType.value === 2) {
            if (resourceDetail.resourceUrl.length === 0) {
                videoRef.current.scrollIntoView({ block: 'start' });
                _handleErrorUpdate("resourceUrlError", "Select video");
                valid = false;
            }
        }
        if (resourceDetail.bannerUrl.length === 0) {
            bannerImageRef.current.scrollIntoView({ block: 'start' });
            _handleErrorUpdate("bannerUrlError", "Select banner");
            valid = false;
        }
        if (resourceDetail.resourceType === null) {
            resourceTypeRef.current.scrollIntoView({ block: 'start' });
            _handleErrorUpdate("resourceTypeError", "Select resource type");
            valid = false;
        }
        if (resourceDetail.category === null) {
            categoryRef.current.scrollIntoView({ block: 'start' });
            _handleErrorUpdate("categoryError", "Select category");
            valid = false;
        }

        if (resourceDetail.title.length === 0) {
            titleRef.current.scrollIntoView({ block: 'start' });
            _handleErrorUpdate("titleError", "Enter title")
            valid = false;
        }

        if (valid === true) {
            if (status === null) {
                _handleSchedule(true);
            }
            else {
                _updateResource(status);
            }
        }

    }

    const _handleErrorUpdate = (property, text) => {
        setResourceDetail((prevState) => ({
            ...prevState,
            [property]: text,
        }));
    }

    const _handleSizeError = (type) => {
        switch (type) {
            case 1:
                _handleErrorUpdate("resourceUrlError", "File size exceeds 20mb")
                break;
            case 2:
                _handleErrorUpdate("bannerUrlError", "File size exceeds 2mb")
                break;
            case 3:
                _handleErrorUpdate("thumbnailUrlError", "File size exceeds 2mb")
                break;
            default:
                break;
        }
    }

    const _handleSchedule = (status) => {
        setShowScheduleModal(status)
    }

    // API - resource detail
    const _getResourceDetail = () => {
        const url = "/resource/detail";
        const request = {
            resource_id: location.state.id,
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                const formatted_data = {
                    resourceId: location.state.id,
                    title: response.data.title,
                    titleError: "",
                    category: { label: response.data.category, value: response.data.category_id },
                    categoryError: "",
                    resourceType: { label: response.data.resource_type, value: response.data.resource_type_id },
                    resourceTypeError: "",
                    resourceUrl: response.data.resource_url === null ? "" : response.data.resource_url,
                    resourceUrlError: "",
                    bannerUrl: response.data.banner_url,
                    bannerUrlError: "",
                    thumbnailUrl: response.data.thumbnail_url === null ? "" : response.data.thumbnail_url,
                    thumbnailUrlError: "",
                    editorState: response.data.content,
                    scheduleDate: response.data.schedule_date,
                    scheduleTime: response.data.schedule_time,
                };
                setResourceDetail(formatted_data);
                setLoader(false);
            }
            else {
                _handleNavigate();
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    const _handleBannerUpload = (file) => {
        const img = new Image();

        // Create an Object URL from the file
        const objectURL = URL.createObjectURL(file);
        img.src = objectURL;

        img.onload = function () {
            const width = img.width;
            const height = img.height;

            // Define your required dimensions
            const maxWidth = 600;
            const maxHeight = 300;


            if (width > maxWidth || height > maxHeight) {
                toast.dismiss()
                toast.error(`Please upload required dimension of image.`, {
                    type: "error"
                });
            } else {
                _handleFileUpload(file, 2)
            }

            // Clean up the Object URL to free up memory
        }
    }

    // API - get file path
    const _handleFileUpload = async (file, type) => {
        switch (type) {
            case 1:
                setVideoLoader(true);
                break;
            case 2:
                setBannerLoader(true);
                break;
            case 3:
                setThumbnailLoader(true);
                break;

            default:
                break;
        }


        const request = {
            file: file.name,
            is_public: false
        }

        await _getFilePath(request).then((response) => {
            toast.dismiss();
            setVideoLoader(false);
            setBannerLoader(false);
            setThumbnailLoader(false);
            _uploadDocumentToS3(response.data, file).then((result) => {
                switch (type) {
                    case 1:
                        _handleChange("resourceUrl", response.data.view_info);
                        break;
                    case 2:
                        _handleChange("bannerUrl", response.data.view_info);
                        break;
                    case 3:
                        _handleChange("thumbnailUrl", response.data.view_info);
                        break;
                    default:
                        break;
                }


            }).catch((error) => {
                toast.error(error, {
                    type: "error"
                });
            });
        });
    }


    // API - update resource
    const _updateResource = (status) => {
        const url = "/resource/upsert";
        const request = {
            resource_id: resourceDetail.resourceId,
            title: resourceDetail.title,
            category_id: resourceDetail.category.value,
            resource_type_id: resourceDetail.resourceType.value,
            resource_url: resourceDetail.resourceType.value === 1 ? null : resourceDetail.resourceUrl,
            thumbnail_url: resourceDetail.thumbnailUrl,
            banner_url: resourceDetail.bannerUrl,
            content: resourceDetail.editorState,
            is_draft: status

        }
        APIService(true, url, request).then((response) => {

            if (response.status_code === 200) {
                navigate("/manage-resource", {
                    state: {
                        tab_index: status === true ? 3 : status === null ? 2 : 1
                    }
                });
                toast.success("Resource updated", {
                    type: "success",
                });
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // API - get filter data
    const _getFilterData = () => {
        const url = "/resource/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setResourceOptions(response.data.resource_type);
                setCategoryOptions(response.data.category);
                if (location.state !== null) {
                    _getResourceDetail();
                }
                else {
                    setLoader(false);
                }
            }
        });
    }

    return (

        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="Manage resources"
                        subTitle="Resource category" />
                </div>
            </div>
            <div className="e-body-wrapper">
                <div className="row ">
                    <div className="col-12">

                        <div className="e-bg-white border-radius-32px padding-40px-tb padding-32px-lr">

                            <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-4">Add resource</p>
                            {
                                loader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div> :
                                    <Fragment>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6" ref={titleRef}>
                                                <CustomTextInput1 label="Title"
                                                    type='text'
                                                    id="title"
                                                    className='mb-4'
                                                    postfix="*"
                                                    placeHolder="Enter title"
                                                    error={resourceDetail.titleError}
                                                    value={resourceDetail.title}
                                                    handleChange={(value) => _handleChange("title", value)} />
                                            </div>
                                            <div className="col-lg-4 col-md-6 pb-md-0 pb-4" ref={categoryRef}>
                                                <CustomSelect type={3}
                                                    postFix={true}
                                                    placeholder="Select category"
                                                    // label_class="e-montserrat-semi-bold"
                                                    label="Category"
                                                    error={resourceDetail.categoryError}
                                                    value={resourceDetail.category}
                                                    options={categoryOptions}
                                                    selectChange={(value) => _handleChange("category", value)} />

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6" ref={resourceTypeRef}>
                                                <CustomSelect type={3}
                                                    postFix={true}
                                                    placeholder="Select resource type"
                                                    // label_class="e-montserrat-semi-bold"
                                                    // label="Category"
                                                    label_class="e-montserrat-semi-bold"
                                                    label="Resource type"
                                                    error={resourceDetail.resourceTypeError}
                                                    value={resourceDetail.resourceType}
                                                    options={resourceOptions}
                                                    selectChange={(value) => _handleChange("resourceType", value)} />

                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 mt-4">
                                            <CustomFileInput shape="square"
                                                label="Upload thumbnail image"
                                                loader={thumbnailLoader}
                                                file={resourceDetail.thumbnailUrl}
                                                postfix={false}
                                                accept={["JPG", "PNG", "JPEG"]}
                                                error={resourceDetail.thumbnailUrlError}
                                                sizeError={() => _handleSizeError(3)}
                                                onSelectFile={(file) => _handleFileUpload(file, 3)}
                                                clearFile={() => _handleChange("thumbnailUrl", "")} />
                                        </div>
                                        {
                                            resourceDetail.resourceType && resourceDetail.resourceType.value === 2 ?
                                                <Fragment>
                                                    <div className="row mt-4">
                                                        <div className="col-lg-4 col-md-6 " ref={videoRef}>
                                                            <CustomFileInput shape="rectangle"
                                                                label="Upload video"
                                                                postfix={true}
                                                                loader={videoLoader}
                                                                type={1}
                                                                file={resourceDetail.resourceUrl}
                                                                maxSize={20}
                                                                accept={["MP4", "MOV", "AVI"]}
                                                                error={resourceDetail.resourceUrlError}
                                                                sizeError={() => _handleSizeError(1)}
                                                                onSelectFile={(file) => _handleFileUpload(file, 1)} />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-lg-5 col-md-6 pb-md-0 pb-4" ref={bannerImageRef}>
                                                            <CustomFileInput shape="rectangle"
                                                                label="Upload video banner image"
                                                                postfix={true}
                                                                loader={bannerLoader}
                                                                file={resourceDetail.bannerUrl}
                                                                accept={["JPG", "PNG", "JPEG"]}
                                                                error={resourceDetail.bannerUrlError}
                                                                sizeError={() => _handleSizeError(2)}
                                                                onSelectFile={(file) => _handleBannerUpload(file)}
                                                                clearFile={() => _handleChange("bannerUrl", "")} />
                                                        </div>

                                                    </div>
                                                </Fragment>
                                                :
                                                <div className="row mt-4" ref={bannerImageRef}>
                                                    <div className="col-lg-8 col-md-8">
                                                        <CustomFileInput shape="rectangle"
                                                            label="Upload banner image"
                                                            postfix={true}
                                                            loader={bannerLoader}
                                                            file={resourceDetail.bannerUrl}
                                                            accept={["JPG", "PNG", "JPEG"]}
                                                            error={resourceDetail.bannerUrlError}
                                                            sizeError={() => _handleSizeError(2)}
                                                            onSelectFile={(file) => _handleBannerUpload(file)}
                                                            dimension="(600 * 300)"
                                                            clearFile={() => _handleChange("bannerUrl", "")} />
                                                    </div>
                                                </div>
                                        }

                                        <div className="row mt-4">
                                            <div className="col-lg-8 col-md-8" ref={contentRef}>
                                                <CustomWysiwygEditor value={resourceDetail.editorState}
                                                    label="Resource content"
                                                    postfix="*"
                                                    error={resourceDetail.editorStateError} // Pass any additional props as needed
                                                    onEditorStateChange={(value) => _handleChange("editorState", value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row margin-40px-top">
                                            <div className="col-lg-8">
                                                <div className="d-flex justify-content-end gap-8px">
                                                    <SecondaryButton label="Save as draft"
                                                        className="padding-14px-tb padding-30px-lr"
                                                        handleClick={() => _handlePublish(true)} />
                                                    <SecondaryButton label="Publish later"
                                                        className="padding-14px-tb padding-30px-lr"
                                                        handleClick={() => _handlePublish(null)} />
                                                    <PrimaryButton label="Publish now"
                                                        className="padding-14px-tb padding-30px-lr"
                                                        handleClick={() => _handlePublish(false)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {
                showScheduleModal === true &&
                <ResourcePublishLater closeModal={() => _handleSchedule(false)}
                    data={resourceDetail}
                    confirm={_handleNavigate} />
            }
        </Fragment>

    )
}

export default memo(ManageResource)
