/*
 *   File : profiles.js
 *   Author : https://evoqins.com
 *   Description : Admin Detail
 *   Version : 1.0.0
*/


// packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";

// Custom components
import { PrimaryButton, TextButton } from "../../Components/Buttons";
import { ChangePassword, EditProfileModal } from "../../Components/Modal";
import { CustomTitle } from "../../Components/Title";
import APIService from "../../Services/api-service";

const Profile = () => {
    const [edit, setEdit] = useState(null);

    const [profileDetails, setProfileDetails] = useState({});
    const [pageLoader, setPageLoader] = useState(true);
    const [changePassword, setChangePassword] = useState(false);

    useEffect(() => {
        _getProfileDetails();
    }, []);

    useEffect(() => {
        if (changePassword === true) {
            const modal = new Modal(document.getElementById("change-password"));
            modal.show()
        }
    }, [changePassword])

    useEffect(() => {
        if (edit !== null) {
            const modal = new Modal(document.getElementById("edit-profile"));
            modal.show()
        }
    }, [edit])


    function _changePassword(status) {
        setChangePassword(status)
    }

    function _handleEdit(status) {
        setEdit(status);
    }

    // API - get profile details

    const _getProfileDetails = () => {
        const url = "/profile/get";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setProfileDetails(response.data);
            }
            setPageLoader(false);
        })
    }
    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <CustomTitle title="Profile & Change Password" />
                </div>
            </div>
            <div className="e-body-wrapper">
                {
                    pageLoader === true ?
                        <div className="h-300px d-flex align-items-center justify-content-center">
                            <img src={require("../../Assets/Images/loader/page-loader.gif")}
                                alt={"loader"}
                                width={60}
                                height={60}
                            />
                        </div>
                        :
                        <div className="bg-white border-radius-32px padding-36px-tb padding-32px-lr">
                            <div className="row align-items-center">
                                <div className="col-9">
                                    <div className="d-flex align-items-center">
                                        <img src={require("../../Assets/Images/Icons/profile.png")}
                                            alt="profile"
                                            draggable={false}
                                            width={120}
                                            height={120}
                                            className="min-w-120px" />
                                        <div className="padding-52px-left">
                                            <h6 className="e-montserrat-semi-bold e-font-20 e-line-height-14 color-dark-charcoal mb-3 word-break">
                                                {profileDetails.username}
                                            </h6>
                                            <div className="e-montserrat-regular e-font-14 e-line-height-24 color-dark-charcoal d-flex gap-4px align-items-center mb-3">
                                                Email: <span className="e-montserrat-semi-bold">{profileDetails.email ? profileDetails.email : "-"}</span>
                                                <TextButton name="Edit" hide_arrow={true}
                                                    className="e-font-14"
                                                    onPress={() => _handleEdit(1)} />
                                            </div>
                                            <div className="e-montserrat-regular e-font-14 e-line-height-24 color-dark-charcoal d-flex gap-4px align-items-center mb-3">
                                                Contact no: <span className="e-montserrat-semi-bold">{profileDetails.mobile ? profileDetails.mobile : "-"}</span>
                                                <TextButton name={profileDetails.mobile ? "Edit" : "Add"} hide_arrow={true}
                                                    className="e-font-14"
                                                    onPress={() => _handleEdit(2)} />
                                            </div>
                                            <div className="e-montserrat-medium e-font-14 e-line-height-24 color-dark-charcoal d-flex gap-4px align-items-center mb-0">
                                                User type: <span className="e-montserrat-semi-bold">{profileDetails.admin_type ? profileDetails.admin_type : "-"}</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <PrimaryButton label="Change password"
                                        className="px-3 padding-14px-tb"
                                        handleClick={() => _changePassword(true)} />
                                </div>
                            </div>
                        </div>
                }
            </div>
            {
                changePassword === true &&
                <ChangePassword closeModal={() => _changePassword(false)} />
            }
            {
                edit !== null &&
                <EditProfileModal type={edit}
                    email={profileDetails.email ? profileDetails.email : ""}
                    phone={profileDetails.mobile ? profileDetails.mobile : ""}
                    userName={profileDetails.username}
                    updateDetails={_getProfileDetails}
                    closeModal={() => _handleEdit(null)} />
            }


        </Fragment>
    )
}

export default Profile