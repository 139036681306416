
/*
 *   File : risk-profile-history.js
 *   Author URI : https://evoqins.com
 *   Description : Risk profile history modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { memo, useEffect, useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { CustomTabBar } from "../Tab";
import { CustomSegmentChart } from "../Chart";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";


const RiskPortfolioHistoryModal = (props) => {
    const [tabIndex, setTabIndex] = useState(1);
    const [loader, setLoader] = useState(true);
    const [tabs, setTabs] = useState([]);
    const [versionData, setVersionData] = useState({});

    useEffect(() => {
        var my_modal = document.getElementById("risk-profile-history");
        my_modal.addEventListener("shown.bs.modal", () => {
            setTimeout(() => {
                setLoader(false);
            }, 20)
        });

    }, [])

    useEffect(() => {
        if (props.versionData.length > 0) {
            const tab_list = []
            //eslint-disable-next-line
            props.versionData.map((item, index) => {
                tab_list.push({
                    label: item.name,
                    id: index + 1
                })
            })
            setTabs(tab_list);
            _handleTabIndex(tab_list[0].id, props.versionData);
        }
        //eslint-disable-next-line
    }, [props.versionData])

    useEffect(() => {
        var my_modal = document.getElementById("risk-profile-history");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });

        //eslint-disable-next-line
    }, [props.closeModal]);

    function _handleTabIndex(tab_index) {
        setTabIndex(tab_index);
        setVersionData(props.versionData[tab_index - 1]);
    }


    return (
        <div className={`modal fade ${style.e_modal} e-risk-modal`}
            id="risk-profile-history"
            tabIndex="-1"
            aria-labelledby="risk-profile-history"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content py-4 padding-32px-lr ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">View previous risk profile</h5>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer' />
                    </div>
                    <div className="mt-4">
                        <CustomTabBar selectedTab={tabIndex}
                            data={tabs}
                            onSelectTab={_handleTabIndex} />
                    </div>
                    <div className={style.e_body_wrapper}>
                        <div className="row padding-32px-top">
                            <div className="col-12 pb-2">
                                <div className="d-flex justify-content-between">
                                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-0">Risk score
                                        <span className="e-font-14 e-line-height-20 e-montserrat-medium color-dark-charcoal">
                                            (Last updated on: {versionData.created})
                                        </span>
                                    </h4>
                                </div>
                            </div>
                            {/* Chart */}
                            <div className="col-lg-4 col-md-6 col-12">
                                {!loader && <div className="e-bg-magnolia e-box-shadow-chart padding-10x-top padding-16px-bottom border-radius-16px position-relative">
                                    <CustomSegmentChart value={versionData.risk_score} width={true} />
                                    <div className="position-absolute top-30per start-50 translate-x-40">
                                        <span className="e-font-8 e-line-height-8 e-montserrat-regular color-black-olive" >
                                            Risk score
                                        </span>
                                        <h6 className="e-font-12 e-line-height-16 e-montserrat-semi-bold color-dark-jungle-green text-center mb-0" >{versionData.risk_score}</h6>
                                    </div>
                                    <h6 className="margin-20px-top color-dark-charcoal e-font-12 e-line-height-10 letter-spacing-011px e-montserrat-semi-bold mb-1 text-center">{versionData.investment_style_text}</h6>
                                </div>}
                            </div>

                            {/* Risk questionnaire details */}
                            <div className="col-12 pt-4">
                                <h4 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-gunmetal-gray mb-0">Risk profile question & answers</h4>
                                <div className="mt-2">
                                    {
                                        versionData.risk_questions !== undefined && versionData.risk_questions.map((item, key) => {
                                            return (
                                                <div key={key} className="pt-3">
                                                    <h5 className="e-font-14 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                                        {item.topic}
                                                    </h5>
                                                    <div className="e-bg-ethereal-lavender border-radius-8px ">
                                                        {
                                                            item.assessment.map((assessment) => {
                                                                return (
                                                                    <div className="py-3 padding-32px-lr">
                                                                        <div className="row d-flex align-items-center">
                                                                            <div className="col-sm-8">
                                                                                <span className="e-font-14 e-line-height-24 e-montserrat-regular color-dark-charcoal">
                                                                                    {assessment.question}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <span className="e-font-14 e-line-height-24 e-montserrat-medium color-dark-charcoal">
                                                                                    {assessment.answer}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(RiskPortfolioHistoryModal)