/*
 *   File : value-config.js
 *   Author URI : www.evoqins.com
 *   Description : Container page to accept config values such as inflation rate, average benchmark return
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import { CustomTitle } from "../../../../Components/Title";
import { CustomTabBar } from "../../../../Components/Tab";
import { PrimaryButton } from "../../../../Components/Buttons";
import { useNavigate } from "react-router-dom";
import { CustomTextInput1, PercentageInput } from "../../../../Components/FormElements";

// Services
import APIService from "../../../../Services/api-service";

const TAB_OPTIONS = [
    // {
    //     label: "Banner images",
    //     id: 1
    // },
    {
        label: "FAQ",
        id: 2
    },
    {
        label: "Terms & conditions",
        id: 3
    },
    {
        label: "Privacy policy",
        id: 4
    },
    {
        label: "Disclaimer",
        id: 5
    },
    {
        label: "Value pair setting",
        id: 6
    },
    {
        label: "User category settings",
        id: 7
    }
]

const ValuePair = () => {

    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(TAB_OPTIONS[4].id);

    const [inflationRate, setInflationRate] = useState(null);
    const [inflationRateError, setInflationRateError] = useState("");

    const [androidVersion, setAndroidVersion] = useState(null);
    const [androidVersionError, setAndroidVersionError] = useState("");

    const [iosVersion, setIosVersion] = useState(null);
    const [iosVersionError, setIosVersionError] = useState("");

    const [sipCount, setSipCount] = useState(null);
    const [sipCountError, setSipCountError] = useState("");

    const [apiLoader, setApiLoader] = useState(null);
    const [pageLoader, setPageLoader] = useState(true);

    const [benchMark, setBenchMark] = useState("");
    const [benchMarkError, setBenchMarkError] = useState("");

    useEffect(() => {
        _getConfig()
    }, [])

    function _handleTab(tab_index) {
        setTabIndex(tab_index);
        switch (tab_index) {
            case 1:
                navigate('/app-settings');
                break;
            case 2:
                navigate('/app-settings');
                break;
            case 3:
                navigate('/app-settings/terms-conditions');
                break;
            case 4:
                navigate('/app-settings/privacy-policy');
                break;
            case 5:
                navigate('/app-settings/disclaimer');
                break;
            case 6:
                navigate('/app-settings/value-pair-setting');
                break;
            case 7:
                navigate('/app-settings/user-category-setting');
                break;

            default:
                break;
        }
    }

    function _handleInflationRate(value) {
        if (value <= 100) {
            setInflationRate(value);
        }
        setInflationRateError("");
    }

    function _handleAndroid(value) {
        if (value <= 100) {
            setAndroidVersion(value);
        }
        setAndroidVersionError("");
    }

    function _handleIos(value) {
        if (value <= 100) {
            setIosVersion(value);
        }
        setIosVersionError("");
    }

    function _handleSip(value) {
        if (value <= 100) {
            setSipCount(value);
        }
        setSipCountError("");
    }

    function _handleBenchMark(value) {
        if (value <= 100) {
            setBenchMark(value);
        }
        setBenchMarkError("");
    }

    function _submitConfig(type, value) {
        let valid = true;
        switch (value) {
            case 1:
                if (inflationRate === null) {
                    setInflationRateError("Inflation is required");
                    valid = false
                }
                break;
            case 2:
                if (androidVersion === null) {
                    setAndroidVersionError("Android version is required");
                    valid = false
                }
                break;
            case 3:
                if (iosVersion === null) {
                    setIosVersionError("Ios version is required");
                    valid = false
                }
                break;
            case 4:
                if (sipCount === null) {
                    setSipCountError("Sip count is required");
                    valid = false
                }
                break;
            case 5:
                if (benchMark === null) {
                    setBenchMarkError("Benchmark is required");
                    valid = false
                }
                break;

            default:
                break;
        }
        if (valid === true) {
            _updateConfig(type, value);
        }
    }

    // API - update config
    const _updateConfig = (type, value) => {
        setApiLoader(value)
        const url = "/config/update";
        const request = {
            "type": type,
            "allocation": value === 1 ? parseFloat(inflationRate) : value === 2 ? parseFloat(androidVersion) : value === 3 ? parseFloat(iosVersion) : parseFloat(sipCount)
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss()
            if (response.status_code === 200) {
                toast.success("Config updated successfully", {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(null);
        })
    }

    // API - get config data
    const _getConfig = () => {
        const url = "/config/get";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let inflation_obj = response.data.filter((item) => item.type === "INFLATION")
                if (inflation_obj.length > 0) {
                    setInflationRate(inflation_obj[0].allocation);
                }

                let benchamark_obj = response.data.filter((item) => item.type === "BENCHMARK")
                if (benchamark_obj.length > 0) {
                    setBenchMark(benchamark_obj[0].allocation);
                }

                let android = response.data.filter((item) => item.type === "ANDROID_VERSION")
                if (android.length > 0) {
                    setAndroidVersion(android[0].allocation);
                }

                let ios = response.data.filter((item) => item.type === "IOS_VERSION")
                if (ios.length > 0) {
                    setIosVersion(ios[0].allocation);
                }

                let sip_count = response.data.filter((item) => item.type === "DAILY_SIP_COUNT")
                if (sip_count.length > 0) {
                    setSipCount(sip_count[0].allocation);
                }

            }
            setPageLoader(false);
        })
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="App Settings" type={1} />
            </div>
            <div className="e-body-wrapper">
                <div className="bg-white border-radius-32px padding-40px-tb ">
                    <div className="row padding-32px-lr">
                        <div className="col-12">
                            <CustomTabBar data={TAB_OPTIONS}
                                selectedTab={tabIndex}
                                onSelectTab={_handleTab} />
                        </div>
                        <div className="col-12 padding-32px-top margin-22px-bottom">
                            <h4 className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal margin-34px-bottom">
                                Value pair setting
                            </h4>

                            {
                                pageLoader === true ?
                                    <div className="h-80vh d-flex align-items-center justify-content-center">
                                        <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                            alt={"loader"}
                                            width={60}
                                            height={60}
                                        />
                                    </div>
                                    :
                                    <div className="e-bg-pastel-lavender border-radius-12px padding-32px-all">
                                        <div className="row align-items-end mb-4">
                                            <div className="col-4">
                                                <PercentageInput label="Inflation rate"
                                                    postFix={true}
                                                    error={inflationRateError}
                                                    value={inflationRate}
                                                    placeHolder="4"
                                                    // min={0}
                                                    inputClass="e-line-height-18 e-font-16 "
                                                    handleChange={_handleInflationRate} />
                                            </div>

                                            <div className="col-2">
                                                <PrimaryButton label="Update"
                                                    className="padding-14px-tb padding-48px-lr w-100"
                                                    loader={apiLoader === 1 ? true : false}
                                                    disabled={apiLoader === 1 ? true : false}
                                                    handleClick={() => _submitConfig("INFLATION", 1)} />
                                            </div>
                                        </div>

                                        <div className="row align-items-end mb-4">
                                            <div className="col-4">
                                                <CustomTextInput1 label="Benchmark"
                                                    postFix={true}
                                                    error={benchMarkError}
                                                    value={benchMark}
                                                    placeHolder="4"
                                                    // min={0}
                                                    is_decimal={true}
                                                    type="number"
                                                    labelClass="e-line-height-20 "
                                                    handleChange={_handleBenchMark} />
                                            </div>

                                            <div className="col-2">
                                                <PrimaryButton label="Update"
                                                    className="padding-14px-tb padding-48px-lr w-100"
                                                    loader={apiLoader === 5 ? true : false}
                                                    disabled={apiLoader === 5 ? true : false}
                                                    handleClick={() => _submitConfig("BENCHMARK", 5)} />
                                            </div>
                                        </div>
                                        <div className="row align-items-end mb-4">
                                            <div className="col-4">
                                                <CustomTextInput1 label="Android version"
                                                    postFix={true}
                                                    error={androidVersionError}
                                                    value={androidVersion}
                                                    placeHolder="4"
                                                    // min={0}
                                                    is_decimal={true}
                                                    type="number"
                                                    labelClass="e-line-height-20 "
                                                    handleChange={_handleAndroid} />
                                            </div>

                                            <div className="col-2">
                                                <PrimaryButton label="Update"
                                                    className="padding-14px-tb padding-48px-lr w-100"
                                                    loader={apiLoader === 2 ? true : false}
                                                    disabled={apiLoader === 2 ? true : false}
                                                    handleClick={() => _submitConfig("ANDROID_VERSION", 2)} />
                                            </div>
                                        </div>

                                        <div className="row align-items-end mb-4">
                                            <div className="col-4">
                                                <CustomTextInput1 label="IOS version"
                                                    postFix={true}
                                                    error={iosVersionError}
                                                    value={iosVersion}
                                                    placeHolder="4"
                                                    // min={0}
                                                    is_decimal={true}
                                                    type="number"
                                                    labelClass="e-line-height-20 "
                                                    handleChange={_handleIos} />
                                            </div>

                                            <div className="col-2">
                                                <PrimaryButton label="Update"
                                                    className="padding-14px-tb padding-48px-lr w-100"
                                                    loader={apiLoader === 3 ? true : false}
                                                    disabled={apiLoader === 3 ? true : false}
                                                    handleClick={() => _submitConfig("IOS_VERSION", 3)} />
                                            </div>
                                        </div>

                                        <div className="row align-items-end mb-4">
                                            <div className="col-4">
                                                <CustomTextInput1 label="Sip count"
                                                    postFix={true}
                                                    error={sipCountError}
                                                    value={sipCount}
                                                    placeHolder="4"
                                                    // min={0}
                                                    type="number"
                                                    is_decimal={true}
                                                    labelClass="e-line-height-20 "
                                                    handleChange={_handleSip} />
                                            </div>

                                            <div className="col-2">
                                                <PrimaryButton label="Update"
                                                    className="padding-14px-tb padding-48px-lr w-100"
                                                    loader={apiLoader === 4 ? true : false}
                                                    disabled={apiLoader === 4 ? true : false}
                                                    handleClick={() => _submitConfig("DAILY_SIP_COUNT", 4)} />
                                            </div>
                                        </div>


                                    </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ValuePair;