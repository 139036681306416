
/*
 *   File : confirm-delete.js
 *   Author URI : https://evoqins.com
 *   Description : Confirm delete modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

const ConfirmDeleteModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("confirm-delete");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    const _deleteBanner = async () => {
        try {
            const result = await props.delete();
            if (result === true) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.updateList()
            }
        } catch (err) {

        }
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal_wrapper}`}
                id="confirm-delete"
                tabIndex="-1"
                aria-labelledby="confirm-delete"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                {props.title}
                            </h5>
                            <Icon icon="close"
                                size={24}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <p className="e-montserrat-medium e-font-16 e-line-height-24 color-dark-charcoal mb-0 mt-4">
                            {props.content}
                        </p>
                        <div className="d-flex justify-content-end margin-40px-top gap-8px">
                            <SecondaryButton label="Cancel" className="padding-14px-tb padding-50px-lr"
                                cancel="modal" />
                            <div className="col-4">

                                <PrimaryButton label={props.label ? props.label : "Delete"} className="padding-14px-tb w-100"
                                    loader={props.loader}
                                    disabled={props.loader}
                                    handleClick={_deleteBanner} />

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default memo(ConfirmDeleteModal)