
/*
 *   File : manage-pyramid.js
 *   Author URI : https://evoqins.com
 *   Description : Manage pyramid modal 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, memo, useEffect, useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { CustomSelect, PercentageInput } from "../FormElements"
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";

const ManagePyramidModal = (props) => {
    const [ageOptions, setAgeOptions] = useState([]);

    const [fromAge, setAgeFrom] = useState(null);
    const [toAge, setAgeTo] = useState(null);

    const [fromAgeError, setFromAgeError] = useState("");
    const [toAgeError, setToAgeError] = useState("");

    const [speculation, setSpeculation] = useState("");
    const [speculationError, setSpeculationError] = useState("");

    const [growth, setGrowth] = useState("");
    const [growthError, setGrowthError] = useState("");

    const [savings, setSavings] = useState("");
    const [savingsError, setSavingsError] = useState("");

    const [protection, setProtection] = useState("");
    const [protectionError, setProtectionError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("manage-pyramid");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            _clearFields();
        });

        _getAges();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.detail !== null) {
            setAgeFrom({ label: props.detail.from_age, value: props.detail.from_age });
            setAgeTo({ label: props.detail.to_age, value: props.detail.to_age });
            setProtection(props.detail.protection);
            setSavings(props.detail.savings);
            setGrowth(props.detail.growth);
            setSpeculation(props.detail.speculation);
        }
    }, [props.detail]);

    function _getAges() {
        let age_options = []
        for (let i = 18; i <= 80; i++) {

            age_options.push({
                label: i,
                value: i
            })

        }
        setAgeOptions(age_options);
    }

    function _handleAge(select_value, type) {
        switch (type) {
            case 1:
                setAgeFrom(select_value);
                setFromAgeError("");
                break;
            case 2:
                setAgeTo(select_value);
                setToAgeError("");
                break;

            default:
                break;
        }
    }

    function _handleSpeculation(input_value) {
        if (input_value <= 100) {
            setSpeculation(input_value);
        }
        setSpeculationError('');
    }

    function _handleGrowth(input_value) {
        if (input_value <= 100) {
            setGrowth(input_value);
        }
        setGrowthError('');
    }

    function _handleSavings(input_value) {
        if (input_value <= 100) {
            setSavings(input_value);
        }
        setSavingsError('');
    }

    function _handleProtection(input_value) {
        if (input_value <= 100) {
            setProtection(input_value);
        }
        setProtectionError('');
    }

    function _clearFields() {
        setAgeFrom(null);
        setToAgeError("");
        setAgeTo(null);
        setFromAgeError("");
        setSpeculation("");
        setSpeculationError("");
        setGrowth("");
        setGrowthError("");
        setSavings("");
        setSavingsError("");
        setProtection("");
        setProtectionError("");
    }

    function _handleSubmitForm() {
        let valid = true;
        if (fromAge === null) {
            setFromAgeError("From age is required");
            valid = false
        }
        if (toAge === null) {
            setToAgeError("To age is required");
            valid = false
        }
        if (speculation === "") {
            setSpeculationError("Speculation is required");
            valid = false
        }
        if (growth === "") {
            setGrowthError("Growth is required");
            valid = false
        }
        if (savings === "") {
            setSavingsError("Savings is required");
            valid = false
        }
        if (protection === "") {
            setProtectionError("Protection is required");
            valid = false
        }
        if (valid === true) {
            const close_button = document.getElementById("close");
            close_button.click();
            _clearFields();
        }
    }
    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal_wrapper}`}
                id="manage-pyramid"
                tabIndex="-1"
                aria-labelledby="manage-pyramid"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-24px-lr padding-32px-tb ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center px-2">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                {props.detail !== null ? "Update financial breakdown" : "Add financial breakdown"}
                            </h5>
                            <Icon icon="close"
                                size={24}
                                id="close-modal"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                            <span id="close" data-bs-dismiss="modal" className="d-none"></span>
                        </div>
                        <div className="margin-32px-top px-2">
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-dark-charcoal margin-12px-bottom">Select age group<span className="color-deep-carmine-pink e-montserrat-regular">*</span></h6>
                            <div className="row">
                                <div className="col-6">
                                    <CustomSelect type={3}
                                        label="From"
                                        postFix={true}
                                        error={fromAgeError}
                                        value={fromAge}
                                        options={ageOptions}
                                        placeholder="Select age from"
                                        label_class="e-line-height-18 letter-spacing-025px"
                                        selectChange={(select_value) => {
                                            _handleAge(select_value, 1);
                                        }} />
                                </div>
                                <div className="col-6">
                                    <CustomSelect type={3}
                                        label="To"
                                        postFix={true}
                                        error={toAgeError}
                                        options={ageOptions}
                                        value={toAge}
                                        placeholder="Select age to"
                                        label_class="e-line-height-18 letter-spacing-025px"
                                        selectChange={(select_value) => {
                                            _handleAge(select_value, 2);
                                        }} />
                                </div>
                            </div>
                            <h6 className="e-font-14 e-line-height-18 e-montserrat-semi-bold color-dark-charcoal margin-12px-bottom mt-4">Add allocation<span className="color-deep-carmine-pink e-montserrat-regular">*</span></h6>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <PercentageInput label="Speculation"
                                        postFix={true}
                                        error={speculationError}
                                        value={speculation}
                                        placeHolder="Speculation"
                                        labelClass="e-line-height-18 letter-spacing-025px"
                                        handleChange={_handleSpeculation} />
                                </div>
                                <div className="col-6">
                                    <PercentageInput label="Growth"
                                        postFix={true}
                                        error={growthError}
                                        value={growth}
                                        placeHolder="Growth"
                                        labelClass="e-line-height-18 letter-spacing-025px"
                                        handleChange={_handleGrowth} />
                                </div>
                            </div>
                            <div className="row padding-12px-top">
                                <div className="col-6">
                                    <PercentageInput label="Savings"
                                        postFix={true}
                                        error={savingsError}
                                        value={savings}
                                        placeHolder="Savings"
                                        labelClass="e-line-height-18 letter-spacing-025px"
                                        handleChange={_handleSavings} />
                                </div>
                                <div className="col-6">
                                    <PercentageInput label="Protection"
                                        postFix={true}
                                        error={protectionError}
                                        value={protection}
                                        placeHolder="Protection"
                                        labelClass="e-line-height-18 letter-spacing-025px"
                                        handleChange={_handleProtection} />
                                </div>
                            </div>
                            <div className="d-flex justify-content-end margin-42px-top gap-8px">
                                <SecondaryButton label="Cancel" className="padding-14px-tb px-5"
                                    cancel="modal" />
                                <div className="col-3">

                                    <PrimaryButton label={props.detail !== null ? "Update" : "Add"}
                                        className="padding-14px-tb w-100"
                                        handleClick={_handleSubmitForm} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ManagePyramidModal.defaultProps = {
    closeModal: () => { }
}

export default memo(ManagePyramidModal);