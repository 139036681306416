/*
 *   File : manage-nudge.js
 *   Author : https://evoqins.com
 *   Description : Conatiner screen to create a new nudge in the application.
 *   Version : 1.0.0
*/
// Packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format, isValid, parse } from "date-fns";

// Components
import { CustomFileInput, CustomSelect, CustomTextInput1, CustomTimePicker } from "../../../Components/FormElements";
import { Breadcrumb, CustomCalendar } from "../../../Components/Others";
import { PrimaryButton, SecondaryButton } from "../../../Components/Buttons";

// Services
import APIService from "../../../Services/api-service";
import { _formatDate, _formatTime } from "../../../Helper/helper";
import { _getFilePath } from "../../../Helper/api";
import { _uploadDocumentToS3 } from "../../../Services/aws-service";


const ManageNudge = () => {

    const disabledDays = { before: new Date() };

    const location = useLocation();
    const navigate = useNavigate();

    const categoryRef = useRef(false);
    const titleRef = useRef(false);
    const bannerImageRef = useRef(false);
    // const contentRef = useRef(false);
    const thumbnailRef = useRef(false);
    const dateRef = useRef(false);
    const timeRef = useRef(false);

    const [nudgeId, setNudgeId] = useState(null);
    const [nudgeTitle, setNudgeTitle] = useState("");
    const [nudgeTitleError, setNudgeTitleError] = useState("");

    const [userCategory, setUserCategory] = useState(null);
    const [categoryError, setCategoryError] = useState("");

    // const [nudgeContent, setNudgeContent] = useState("");
    // const [nudgeContentError, setNudgeContentError] = useState("");

    const [redirectionUrl, setRedirectionUrl] = useState("");
    const [redirectionUrlError, setRedirectionUrlError] = useState("");

    const [navType, setNavType] = useState(null);
    const [navTypeError, setNavTypeError] = useState("");

    const [resourceType, setResourceType] = useState(null);
    const [resourceTypeError, setResourceTypeError] = useState("");

    const [goalType, setGoalType] = useState(null);
    const [goalTypeError, setGoalTypeError] = useState("");

    const [bannerImage, setBannerImage] = useState("");
    const [bannerImageError, setBannerImageError] = useState("");
    const [bannerLoader, setBannerLoader] = useState(false);

    const [webImage, setWebImage] = useState("");
    const [webImageError, setWebImageError] = useState("");
    const [webImageLoader, setWebImageLoader] = useState(false);

    const [scheduledDate, setScheduledDate] = useState("DD-MM-YYYY");
    const [scheduledDateError, setScheduledDateError] = useState("");
    const [defaultMonth, setDefaultMonth] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState(location.state !== null ? _formatTime(location.state.data.start_time) : null);
    const [selectedTimeError, setSelectedTimeError] = useState("");

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [navTypeOptions, setNavTypeOptions] = useState([]);
    const [resourceOptions, setResourceOptions] = useState([]);
    const [goalOptions, setGoalOptions] = useState([]);
    // const [typeOptions, setTypeOptions] = useState([]);
    const [dateLoader, setDateLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);

    useEffect(() => {
        _getNudgeFilter();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let nav_options = []
        if (location.state !== null && pageLoader === false) {
            setDateLoader(true);
            setNudgeId(location.state.data.nudge_id);
            setNudgeTitle(location.state.data.title);
            setUserCategory({ label: location.state.data.user_category_text, value: location.state.data.user_category });
            if (location.state.data.banner_image) {
                setBannerImage(location.state.data.banner_image);
            }
            if (location.state.data.web_image) {
                setWebImage(location.state.data.web_image);
            }
            setRedirectionUrl(location.state.data.redirection_url);
            setNavType({ label: location.state.data.nav_type_text, value: location.state.data.nav_type });

            if (location.state.data.nav_id !== null) {

                if (location.state.data.nav_type === 3) {
                    nav_options = resourceOptions
                } else if (location.state.data.nav_type === 4) {
                    nav_options = goalOptions
                }

                const nav_id_index = nav_options.findIndex((item) => item.value === location.state.data.nav_id);
                const nav_id = nav_options[nav_id_index]
                if (location.state.data.nav_type === 3) {
                    setResourceType(nav_id)
                } else if (location.state.data.nav_type === 4) {
                    setGoalType(nav_id);
                }
            }

            // setNudgeContent(location.state.data.content);
            const [day, month, year] = location.state.data.start_date.split('-');
            const start_date = new Date(`${year}-${month}-${day}`);
            _handleDate(start_date);
            setTimeout(() => {
                setDateLoader(false);
            }, 200);
        }
        //eslint-disable-next-line
    }, [location, pageLoader]);


    const _handleNavigate = () => {
        navigate(-1);
    }

    const _handleTitle = (title) => {
        setNudgeTitle(title);
        setNudgeTitleError("");
    }

    function _handleCategory(select_value) {
        setUserCategory(select_value)
        setCategoryError("");
    }


    // function _handleUserType(select_value) {
    //     setUserType(select_value)
    //     setUserTypeError("");
    // }

    const _handleFileUpload = async (file, type) => {
        if (type === 1) {
            setBannerLoader(true);
        } else {
            setWebImageLoader(true);
        }
        const request = {
            file: file.name,
            is_public: true
        }

        await _getFilePath(request).then(async (response) => {
            toast.dismiss();
            await _uploadDocumentToS3(response.data, file).then((result) => {
                setTimeout(() => {
                    if (type === 1) {
                        setBannerImage(response.data.view_info);
                    } else {
                        setWebImage(response.data.view_info);
                    }
                }, 100)
                setBannerLoader(false);
                setWebImageLoader(false);

            }).catch((error) => {
                toast.error(error, {
                    type: "error"
                });
            });


        });

    }

    function _handleDate(selected_date) {
        if (isValid(selected_date)) {
            const formatted_date = _formatDate(selected_date);
            setDefaultMonth(selected_date);
            setScheduledDate(formatted_date);
            setScheduledDateError("");
        } else {
            setScheduledDate("DD-MM-YYYY");
        }
    }

    function _handleTimeChange(newTime) {
        setSelectedTime(newTime);
        setSelectedTimeError("");
    };

    // function _handleNotificationContent(value) {
    //     setNudgeContent(value);
    //     setNudgeContentError("");
    // }


    function _handleNavType(select_value) {
        setNavType(select_value);
        setNavTypeError("");
    }


    function _handleResourceType(select_value) {
        setResourceType(select_value);
        setResourceTypeError("");
    }


    function _handleGoalType(select_value) {
        setGoalType(select_value);
        setGoalTypeError("");
    }

    function _handleRedirectionUrl(input_value) {
        setRedirectionUrl(input_value);
        setRedirectionUrlError("");
    }

    function _handleCreate() {
        let valid = true;
        if (selectedTime === null) {
            timeRef.current.scrollIntoView({ block: 'start' });
            setSelectedTimeError("Time is required");
            valid = false;
        }
        if (scheduledDate === "DD-MM-YYYY") {
            dateRef.current.scrollIntoView({ block: 'start' });
            setScheduledDateError("Date is required");
            valid = false;
        }
        if (webImage.length === 0) {
            thumbnailRef.current.scrollIntoView({ block: 'start' });
            setWebImageError("Web image is required");
            valid = false;
        }
        if (bannerImage.length === 0) {
            bannerImageRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setBannerImageError("Mobile banner image is required");
        }
        if (navType !== null && navType.value === 5 && redirectionUrl === "") {
            valid = false;
            setRedirectionUrlError("Redirection url is required");
        }
        // if (nudgeContent.length === 0) {
        //     contentRef.current.scrollIntoView({ block: 'start' });
        //     valid = false;
        //     setNudgeContentError("Content is required");
        // }
        if (nudgeTitle.length === 0) {
            titleRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setNudgeTitleError("Notification is required");
        }
        if (navType !== null && navType.value === 3 && resourceType === null) {
            categoryRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setResourceTypeError("Resource type is required");
        }
        if (navType !== null && navType.value === 4 && goalType === null) {
            categoryRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setGoalTypeError("Goal type is required");
        }
        if (navType === null) {
            categoryRef.current.scrollIntoView({ block: 'start' });
            valid = false;
            setNavTypeError("Navigation type is required");
        }
        if (userCategory === null) {
            categoryRef.current.scrollIntoView({ block: 'start' });
            setCategoryError("Category is required");
            valid = false;
        }
        if (valid === true) {
            _manageNudge();
        }
    }

    function _handleCancel() {
        navigate(-1);
    }

    function _handleSizeError(type) {
        switch (type) {
            case 1:
                setBannerImageError("File size exceeds 2mb");
                break;
            case 2:
                setWebImageError("File size exceeds 2mb");
                break;
            default:
                break;
        }
    }

    // API - Nudge filter data
    const _getNudgeFilter = () => {
        const url = "/nudge/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setCategoryOptions(response.data.user_category);
                _getBannerFilter();
            }
        })
    }

    // API - Manage Nudge
    const _manageNudge = () => {
        setApiLoader(true);

        const url = "/nudge/upsert";

        const date = parse(selectedTime, 'hh:mm a', new Date());
        const time = format(date, 'HH:mm:ss');

        const request = {
            "nudge_id": nudgeId,
            "user_category": userCategory.value,
            "nav_type": navType !== null ? navType.value : null,
            "nav_id": navType !== null ? navType.value === 3 ? resourceType.value : navType.value === 4 ? goalType.value : null : null,
            "title": nudgeTitle,
            "redirection_url": redirectionUrl,
            "banner_image": bannerImage,
            "web_image": webImage,
            "scheduled_date": scheduledDate,
            "scheduled_time": time
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success(location.state !== null ? "Nudge updated successfully" : "Nudge created successfully", {
                    type: "success"
                });
                navigate(-1);
            } else {
                toast.error(response.message, {
                    type: "error"
                })
            }
            setApiLoader(false);
        });
    }

    // API - Banner filter 
    const _getBannerFilter = async () => {
        const url = "/banner/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setNavTypeOptions(response.data.nav_type);
                _getResources();
            }
        });
    }


    // API - get resources
    const _getResources = () => {
        const url = "/banner/get-resource";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setResourceOptions(response.data);
                _getGoals();
            }
        });
    }

    // API - get goals
    const _getGoals = () => {
        const url = "/banner/get-goals";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setGoalOptions(response.data);
                setPageLoader(false);
            }
        });
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <div className="padding-110px-bottom">
                    <Breadcrumb handleNavigate={_handleNavigate}
                        mainTitle="App nudges"
                        subTitle="Create nudge" />
                </div>
            </div>
            {
                pageLoader === true ?
                    <div className="h-80vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    <div className="e-body-wrapper">
                        <div className="row ">
                            <div className="col-12">
                                <div className="e-bg-white border-radius-32px padding-40px-tb padding-32px-lr">

                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-dark-charcoal mb-4">{location.state !== null ? "Update nudge" : "Create nudge"}</p>
                                    <div className="row">
                                        <div className="col-4" ref={categoryRef}>
                                            <CustomSelect
                                                type={3}
                                                postFix={true}
                                                placeholder="Select category"
                                                // label_class="e-montserrat-semi-bold"
                                                label="User category"
                                                error={categoryError}
                                                value={userCategory}
                                                options={categoryOptions}
                                                selectChange={_handleCategory} />

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-4 mt-4">
                                            <CustomSelect type={3}
                                                postFix={true}
                                                placeholder="Navigation type"
                                                label="Navigation type"
                                                error={navTypeError}
                                                value={navType}
                                                options={navTypeOptions}
                                                selectChange={_handleNavType} />
                                        </div>

                                        {
                                            navType !== null && navType.value === 3 &&
                                            <div className="col-4 mt-4">
                                                <CustomSelect type={3}
                                                    postFix={true}
                                                    placeholder="Resource type"
                                                    label="Resource type"
                                                    error={resourceTypeError}
                                                    value={resourceType}
                                                    options={resourceOptions}
                                                    selectChange={_handleResourceType} />
                                            </div>
                                        }
                                        {
                                            navType !== null && navType.value === 4 &&
                                            <div className="col-4 mt-4">
                                                <CustomSelect type={3}
                                                    postFix={true}
                                                    placeholder="Goal type"
                                                    label="Goal type"
                                                    error={goalTypeError}
                                                    value={goalType}
                                                    options={goalOptions}
                                                    selectChange={_handleGoalType} />
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-8" ref={titleRef}>
                                            <CustomTextInput1 label="Nudge title"
                                                type='text'
                                                id="nudgeTitle"
                                                postfix="*"
                                                placeHolder="Enter title"
                                                error={nudgeTitleError}
                                                value={nudgeTitle}
                                                handleChange={_handleTitle} />
                                        </div>

                                        {navType !== null && navType.value === 5 && <div className="col-8 mt-4">
                                            <CustomTextInput1 label="Redirection url"
                                                placeHolder="https://mintit.in"
                                                postfix="*"
                                                type="text"
                                                inputClass="e-redirection-input"
                                                error={redirectionUrlError}
                                                value={redirectionUrl}
                                                handleChange={_handleRedirectionUrl} />
                                        </div>}
                                    </div>


                                    <div className="row mt-4">
                                        <div className="col-8" ref={bannerImageRef}>
                                            <CustomFileInput shape="rectangle"
                                                label="Upload mobile banner image"
                                                postfix={true}
                                                file={bannerImage}
                                                accept={["JPG", "PNG", "JPEG"]}
                                                error={bannerImageError}
                                                loader={bannerLoader}
                                                sizeError={() => _handleSizeError(1)}
                                                onSelectFile={(file) => _handleFileUpload(file, 1)}
                                                clearFile={() => setBannerImage("")} />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-8" ref={thumbnailRef}>
                                            <CustomFileInput shape="square"
                                                label="Upload web image"
                                                postfix={true}
                                                file={webImage}
                                                accept={["JPG", "PNG", "JPEG"]}
                                                error={webImageError}
                                                loader={webImageLoader}
                                                sizeError={() => _handleSizeError(2)}
                                                onSelectFile={(file) => _handleFileUpload(file, 2)}
                                                clearFile={() => setWebImage("")} />
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        {dateLoader === false && <div className="col-4" ref={dateRef}>
                                            <CustomCalendar error={scheduledDateError}
                                                disabledDays={disabledDays}
                                                label="Schedule date"
                                                postfix={true}
                                                start={scheduledDate}
                                                mode="single"
                                                className="e-date-input"
                                                defaultMonth={defaultMonth}
                                                selectDate={_handleDate} />
                                        </div>}
                                        <div className="col-4" ref={timeRef}>
                                            <CustomTimePicker label="Schedule time"
                                                error={selectedTimeError}
                                                postFix={true}
                                                time={selectedTime}
                                                onTimeChange={_handleTimeChange} />
                                        </div>
                                    </div>
                                    <div className="row margin-40px-top">
                                        <div className="col-8">
                                            <div className="d-flex justify-content-end gap-8px">
                                                <SecondaryButton label="Cancel"
                                                    className="padding-14px-tb padding-50px-lr"
                                                    handleClick={_handleCancel} />
                                                <div className="col-3">
                                                    <PrimaryButton label={location.state !== null ? "Update" : "Create"}
                                                        className="padding-14px-tb w-100"
                                                        handleClick={_handleCreate}
                                                        loader={apiLoader}
                                                        disabled={apiLoader} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Fragment>

    )
}

export default ManageNudge
