/*
 *   File : file-input.js
 *   Author : https://evoqins.com
 *   Description : UI component to select file from local.
 *   Integrations : null
 *   Version : 1
*/

import { Fragment, memo, useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import { Icon } from "../Icon";

import style from "../../Styles/Components/file-input.module.scss"
import { TextButton } from "../Buttons";

function CustomFileInput(props) {

    const [fileSize, setFileSize] = useState(null);
    // file upload function 
    const handleChange = (file, is_clear) => {
        if (is_clear) {
            props.clearFile();
            setFileSize(null);
        } else {
            if (file) {
                // console.log("File Name:",  URL.createObjectURL(event.target.files[0]));
                props.onSelectFile(file);
                setFileSize(file.size);
            }
        }
    };

    const _handleVideoClick = () => {
        window.open(props.file, '_blank');
    }

    return (
        <Fragment>
            <p className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-2">{props.label}{props.postfix && <span className="color-deep-carmine-pink">*</span>}</p>

            {/* when file is present */}
            {props.file &&
                !(props.accept.includes("MP4") || props.accept.includes("MOV") || props.accept.includes("AVI"))
                ?
                <div className={`${style.e_file} position-relative e-bg-thistle d-flex align-items-center flex-column text-center cursor-pointer overflow-hidden`}>
                    <img src={props.file} alt="file"
                        height={114}
                        className="object-fit-cover w-100 h-120px" />
                    <Icon icon="clear"
                        size={26}
                        className="position-absolute top-6px right-6px z-index-1"
                        onClick={props.clearFile} />
                    <div className={`${style.e_preview} position-absolute top-0 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center flex-column `}>
                        <Icon icon="file-upload"
                            size={26}
                            className="margin-10px-bottom" />
                        <p className="e-montserrat-semi-bold e-font-14 e-line-height-18 color-rebecca-purple mb-0">Change file <span className="e-montserrat-regular color-dark-charcoal">or drag & drop to upload</span></p>
                        <p className="e-montserrat-regular e-font-12 e-line-height-18 color-dark-charcoal mb-0">Maximum file size: 2MB {props.dimension}</p>
                        <TextButton name="View" hide_arrow={true}
                            onPress={() => window.open(props.file, "_blank")} />
                    </div>
                </div>
                :
                // when file is not present
                props.loader === true ?
                    <div className={`${style.e_file} d-flex justify-content-center align-items-center`}>
                        <img src={require("../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                            className="w-60px object-fit-contain" />
                    </div>
                    :
                    props.file &&
                        !(props.accept.includes("MP4") || props.accept.includes("MOV") || props.accept.includes("AVI"))
                        ?
                        <div className={`${style.e_file} position-relative e-bg-thistle d-flex align-items-center flex-column text-center cursor-pointer overflow-hidden`}>
                            <img src={props.file} alt="file"
                                height={114}
                                className="object-fit-cover w-100 h-120px" />
                            <Icon icon="clear"
                                size={26}
                                className="position-absolute top-6px right-6px z-index-2"
                                onClick={props.clearFile} />
                            <div className={`${style.e_preview} position-absolute top-4px right-4px bottom-4px left-4px w-fit-content h-fit-content right mx-auto my-auto`}>
                                <Icon icon="file-upload"
                                    size={26}
                                    className="margin-10px-bottom" />
                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-18 color-rebecca-purple mb-0">Change file <span className="e-montserrat-regular color-dark-charcoal">or drag & drop to upload</span></p>
                                <p className="e-montserrat-regular e-font-12 e-line-height-18 color-dark-charcoal mb-0">Maximum file size: 2MB </p>
                            </div>
                        </div>
                        :
                        // when file is not present

                        <FileUploader
                            handleChange={handleChange}
                            name="file"
                            maxSize={props.maxSize}
                            onSizeError={props.sizeError}
                            classes="w-100 e-file-label"
                            types={props.accept}
                            disabled={props.disabled}
                            children={
                                <div className="position-relative ">

                                    {

                                        <div className={`${style.e_file} e-bg-thistle ${props.shape === "rectangle" ? "py-4 padding-10px-lr" : "p-1"} d-flex align-items-center justify-content-center flex-column text-center cursor-pointer`}>
                                            <Fragment>
                                                <Icon icon="file-upload"
                                                    size={26}
                                                    className="margin-10px-bottom" />
                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-18 color-rebecca-purple mb-0">Choose file <span className="e-montserrat-regular color-dark-charcoal">or drag & drop to upload</span></p>
                                                <p className="e-montserrat-regular e-font-12 e-line-height-18 color-dark-charcoal mb-0">Maximum file size: {props.type === 1 ? "20MB" : "2MB"} {props.dimension}</p>
                                            </Fragment>
                                        </div>
                                    }

                                    {
                                        props.error && (
                                            <p className='position-absolute color-deep-carmine-pink e-montserrat-medium e-font-12 margin-6px-top e-line-height-16 mb-0 '>
                                                {props.error}
                                            </p>
                                        )
                                    }
                                </div>
                            }
                        />
            }

            {/* Video preview */}
            {
                //eslint-disable-next-line
                props.file &&
                (props.accept.includes("MP4") || props.accept.includes("MOV") || props.accept.includes("AVI")) &&
                <div className="padding-12px-tb padding-14px-lr border-1px border-light-gray border-radius-8px mt-4">
                    <div className="d-flex gap-8px justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-12px">
                            <img src={require("../../Assets/Images/file-upload/video-preview.png")} alt={props.file?.name}
                                width={28}
                                height={28} />
                            <div>
                                <p className="e-montserrat-medium e-font-12 e-line-height-18 color-dark-charcoal mb-0">{props.file?.name}</p>
                                <div className="d-flex gap-8px align-items-center">
                                    <p className="e-montserrat-regular e-font-10 e-line-height-18 color-dark-charcoal mb-0">File size: {props.file?.size ?
                                        (props.file.size / 1048576).toFixed(2)
                                        : (fileSize / 1048576).toFixed(2)}
                                        MB</p>
                                    <div className="d-flex align-items-center gap-4px">
                                        <img src={require("../../Assets/Images/file-upload/file-upload-success.png")} alt="File success"
                                            width={9}
                                            height={9} />
                                        <p className="e-montserrat-regular e-font-10 e-line-height-18 color-dark-charcoal mb-0">Completed</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="e-montserrat-semi-bold e-font-12 e-line-height-18 color-rebecca-purple mb-0 cursor-pointer"
                            onClick={_handleVideoClick}>Preview</p>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default memo(CustomFileInput);

CustomFileInput.defaultProps = {
    sizeError: () => { },
    maxSize: 1,
    type: 2,
    loader: false
}

// type 1 is video upload otherwise image