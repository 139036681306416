/*
 *   File : set-password.js
 *   Author : https://evoqins.com
 *   Description : Auth file to set password Admin
 *   Version : 1.0.0
*/

// packages
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import { PrimaryButton } from "../../Components/Buttons"
import { CustomPasswordInput, CustomTextInput1 } from "../../Components/FormElements"


// services
import { _emailValidator } from "../../Helper/helper";
import APIService from "../../Services/api-service";


const SetPassword = () => {
    const navigate = useNavigate();


    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        const email_value = window.location.href.split("=")[1]
        if (email_value) {
            setEmail(email_value);
        }
    }, []);


    // name handler
    function _handleEmail(input_value) {
        setEmail(input_value);
        setEmailError("");
    }

    // password handler
    function _handlePassword(input_value) {
        setPassword(input_value);
        setPasswordError("");
    }

    // confirm password handler
    function _handleConfirmPassword(input_value) {
        setConfirmPassword(input_value);
        setConfirmPasswordError("");
    }

    // otp value handler
    function _handleOTP(input_value) {
        setOtp(input_value);
        setOtpError("");
    }

    // password toggle
    function _handlePasswordToggle() {
        setShowPassword(!showPassword)
    }

    // password toggle
    function _handleConfirmPasswordToggle() {
        setShowConfirmPassword(!showConfirmPassword)
    }

    // Submit fields
    function _handleSubmit() {
        let valid = true;
        if (email === "") {
            valid = false;
            setEmailError("Email cannot be empty");
        }

        if (email !== "" && _emailValidator(email) === false) {
            valid = false;
            setEmailError("Invalid email");
        }

        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (password === "") {
            setPasswordError("Password is required");
            valid = false;
        }

        if (password !== "" && password.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
            valid = false;
        }
        if (confirmPassword === "") {
            setConfirmPasswordError("Confirm password is required");
            valid = false;
        }

        if (confirmPassword !== "" && confirmPassword.length < 8) {
            setPasswordError("Password must be at least 8 characters long");
            valid = false;
        }
        if (confirmPassword !== "" && password !== "" && password !== confirmPassword) {
            setConfirmPasswordError("Password not matching");
            valid = false;
        }
        if (valid === true) {
            _setPassword()
        }
    }

    // API :: set password
    const _setPassword = () => {

        setApiLoader(true);

        const url = "/auth/set-password";
        const request = {
            "email": email,
            "otp": otp,
            "password": confirmPassword
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();

            if (response.status === true) {
                toast.success('Password set successfully', {
                    type: 'success'
                });
                navigate("/");
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }
    return (
        <div className="padding-64px-tb text-center e-login h-100">
            <img src={require("../../Assets/Images/LeftNavigation/logo.png")}
                alt="logo"
                width={100}
                height={100}
                className="mx-auto cursor-pointer object-fit-contain"
                onClick={() => navigate("/")} />
            <div className="padding-32px-all border-radius-16px mx-auto bg-white e-wrapper text-start">
                <h3 className="e-montserrat-semi-bold e-font-20 e-line-height-24 color-dark-charcoal mb-4">
                    Set Password
                </h3>
                <CustomTextInput1 label="Enter the registered email"
                    postfix="*"
                    type="email"
                    placeHolder="Enter registered email"
                    inputClass="text-transform-lower"
                    value={email}
                    error={emailError}
                    isDisabled={true}
                    handleChange={_handleEmail}
                    onSubmit={_handleSubmit} />

                <CustomTextInput1 label="Enter the OTP"
                    postfix="*"
                    type="text"
                    placeHolder="Enter the OTP"
                    value={otp}
                    error={otpError}
                    className='mt-4'
                    handleChange={_handleOTP}
                    onSubmit={_handleSubmit} />
                <CustomPasswordInput label="Password"
                    postfix="*"
                    type={showPassword === true ? "text" : "password"}
                    value={password}
                    error={passwordError}
                    placeHolder="Enter password"
                    show={showPassword}
                    className='mt-4'
                    togglePassword={_handlePasswordToggle}
                    onSubmit={_handleSubmit}
                    handleChange={_handlePassword} />

                <CustomPasswordInput label="Confirm password"
                    postfix="*"
                    type={showConfirmPassword === true ? "text" : "password"}
                    value={confirmPassword}
                    error={confirmPasswordError}
                    placeHolder="Confirm password"
                    className='mt-4'
                    show={showConfirmPassword}
                    onSubmit={_handleSubmit}
                    togglePassword={_handleConfirmPasswordToggle}
                    handleChange={_handleConfirmPassword} />
                <div className="col-4 d-block margin-32px-top">
                    <PrimaryButton label="Confirm"
                        className="w-100 padding-14px-tb"
                        loader={apiLoader}
                        disabled={apiLoader}
                        handleClick={_handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default SetPassword