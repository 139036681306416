/*
 *   File : segment.js
 *   Author : https://evoqins.com
 *   Description : Normal bar component
 *   Integrations : react-guage-component
 *   Version : 1.0.0
*/

// Packages
import { memo } from "react";
import GaugeComponent from "react-gauge-component";

// Configuration
import Config from "../../Helper/config";

const CustomSegmentChart = (props) => {

    return (
        <div className="position-relative ">
            <GaugeComponent
                type="semicircle"
                className="e-guage-component mx-auto"
                style={{
                    width: "100%",
                    height: "100px"
                }}
                arc={{
                    colorArray: Config.SEGMENT_COLORS,
                    cornerRadius: 100,
                    radial: 0.8,
                    width: props.width ? 0.22 : 0.16,
                    subArcs:
                        [{ limit: 30 }, { limit: 60 }, { limit: 80 }, { limit: 100 }]
                }}
                pointer={{
                    type: "blob",
                    animationDelay: 0,
                    color: "#FE625F",
                    width: 25
                }}
                labels={{
                    valueLabel: {
                        hide: true
                    },
                    tickLabels: {
                        hideMinMax: true
                    }
                }}
                value={props.value * 10}

            />

        </div>
    )
}

export default memo(CustomSegmentChart)