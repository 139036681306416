/*
 *   File : assign-ticket.js
 *   Author URI : https://evoqins.com
 *   Description : Assign Ticket modal 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, memo, useEffect, useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { CustomSelect } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/modal.module.scss";
import APIService from "../../Services/api-service";
import { toast } from "react-toastify";


const AssignTicketModal = (props) => {

    const [agent, setAgent] = useState(null);
    const [agentError, setAgentError] = useState("");

    const [priority, setPriority] = useState(null);
    const [priorityError, setPriorityError] = useState("");

    const [status, setStatus] = useState(null);
    const [statusError, setStatusError] = useState("");

    const [category, setCategory] = useState(null);
    const [categoryError, setCategoryError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("assign-ticket");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            setAgent(null);
            setCategory(null);
            setStatus(null);
            setPriority(null);
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.detail !== null) {
            const agent_option_index = props.ticketFilter.admin.findIndex((item) => item.value === props.detail.agent_id)
            const admin_type = props.ticketFilter.admin[agent_option_index]
            setAgent(admin_type)
            setStatus({ label: props.detail.status, value: props.detail.status_id })
            setPriority({ label: props.detail.priority_text, value: props.detail.priority })
            setCategory({ label: props.detail.category, value: props.detail.category_id })
        }
        //eslint-disable-next-line
    }, [props.detail])

    // handler agent field
    function _handleAgent(select_value) {
        setAgent(select_value);
        setAgentError("");
    }

    // handler priority field
    function _handlePriority(select_value) {
        setPriority(select_value);
        setPriorityError("");
    }



    // handler status field
    function _handleStatus(select_value) {
        setStatus(select_value);
        setStatusError("");
    }

    // handler category
    function _handleCategory(select_value) {
        setCategory(select_value);
        setCategoryError("");
    }

    // handle submit validation
    function _handleSubmit() {
        let valid = true;
        if (agent === null) {
            setAgentError("Agent name is required");
            valid = false
        }
        if (priority === null) {
            setPriorityError("Priority is required");
            valid = false
        }
        if (status === null) {
            setStatusError("Status is required");
            valid = false
        }
        if (category === null) {
            setCategoryError("Category is required");
            valid = false
        }

        if (valid === true) {
            _assignTicket();
        }
    }

    // API -assign
    const _assignTicket = () => {

        setApiLoader(true);

        const url = "/support/assign";

        const request = {
            "ticket_id": props.id,
            "admin_id": agent.value,
            "priority": priority.value,
            "status": status.value,
            "category_id": category.value
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success(response.message, {
                    type: 'success'
                });
                document.getElementById("close").dispatchEvent(new Event("click"));
                props.updateList()
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })
    }
    return (
        <Fragment>
            <div className={`modal fade ${style.e_modal_wrapper}`}
                id="assign-ticket"
                tabIndex="-1"
                aria-labelledby="assign-ticket"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-32px-all ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-dark-charcoal mb-0">
                                Assign ticket
                            </h5>
                            <Icon icon="close"
                                size={24}
                                id="close"
                                data-bs-dismiss="modal"
                                className='cursor-pointer' />
                        </div>
                        <div className="row padding-32px-top">
                            <div className="col-6">
                                <CustomSelect type={3}
                                    label="Assign to agent"
                                    postFix={true}
                                    value={agent}
                                    error={agentError}
                                    options={props.ticketFilter.admin}
                                    placeholder="Select agent"
                                    selectChange={_handleAgent} />
                            </div>
                            <div className="col-6">
                                <CustomSelect type={3}
                                    label="Priority level"
                                    postFix={true}
                                    value={priority}
                                    error={priorityError}
                                    options={props.ticketFilter.priority}
                                    placeholder="Select priority"
                                    selectChange={_handlePriority} />
                            </div>
                            <div className="col-6 pt-4">
                                <CustomSelect type={3}
                                    label="Ticket status"
                                    postFix={true}
                                    value={status}
                                    error={statusError}
                                    options={props.ticketFilter.status}
                                    placeholder="Select status"
                                    selectChange={_handleStatus} />
                            </div>
                            <div className="col-6 pt-4">
                                <CustomSelect type={3}
                                    label="Ticket category"
                                    postFix={true}
                                    value={category}
                                    error={categoryError}
                                    options={props.ticketFilter.category}
                                    placeholder="Select category"
                                    selectChange={_handleCategory} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end gap-16px mt-5">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="padding-14px-tb padding-50px-lr" />
                            <div className="col-3">

                                <PrimaryButton label={props.detail !== null ? "Update" : "Add"}
                                    loader={apiLoader}
                                    disabled={apiLoader}
                                    className="padding-14px-tb w-100"
                                    handleClick={_handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default memo(AssignTicketModal)