/*
 *   File : listing.js
 *   Author : https://evoqins.com
 *   Description : Order list
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

// Custom components
import { DataTableContainer } from "../../../Components/DataTable";
import { CustomSelect, CustomTextInput, SearchInput } from "../../../Components/FormElements";
import { Icon } from "../../../Components/Icon";
import { Badge } from "../../../Components/Badge";
import { CustomTitle } from "../../../Components/Title";
import { InfoCard } from "../../../Components/Cards";
import { PrimaryButton, DownloadButton, SecondaryButton, LinkButton } from "../../../Components/Buttons";
import { CustomCalendar, EmptyScreen } from "../../../Components/Others";

// Custom styles
import Colors from '../../../Styles/color.module.scss';
// import { CustomCalendar } from "../../Components/Others";

// Services
import { _forceNavigate, _formatDate } from "../../../Helper/helper";
import APIService from "../../../Services/api-service";
import { _getOrderFilterData } from "../../../Helper/api";
import Config from "../../../Helper/config";


// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "24px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "0px",
            paddingBottom: '0px',

        }
    },
    rows: {
        style: {
            paddingLeft: "24px",
            borderBottom: 'none !important',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',
            },
            '&:hover': {
                backgroundColor: Colors.lavender_mist
            }

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "24px",
            paddingLeft: "0px",
            cursor: "pointer",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};


const OrdersListing = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const disabledDays = { after: new Date() };

    const [search, setSearch] = useState("");
    const [filterEnabled, setFilterEnabled] = useState(false);
    // const [selectedIds, setSelectedIds] = useState([]);
    const [orderOptions, setOrderOptions] = useState([]);
    const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
    const [orderStatusOptions, setOrderStatusOptions] = useState([]);
    const [mileStoneOptions, setMileStoneOptions] = useState([]);

    const [selectedOrderType, setSelectedOrderType] = useState(null);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [amountFrom, setAmountFrom] = useState("");
    const [amountTo, setAmountTo] = useState("");
    const [fromDate, setFromDate] = useState("DD-MM-YYYY");
    const [endDate, setEndDate] = useState("DD-MM-YYYY");

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [milestone, setMilestone] = useState(null);
    const [amtFrom, setAmtFrom] = useState("");
    const [amtTo, setAmtTo] = useState("");
    const [from, setFrom] = useState("DD-MM-YYYY");
    const [end, setEnd] = useState("DD-MM-YYYY");
    const [searchValue, setSearchValue] = useState("");

    const [showTooltip, setShowTooltip] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const [tooltipContent, setTooltipContent] = useState("");
    const [summaryData, setSummaryData] = useState({});
    const [screenLoader, setScreenLoader] = useState(true);
    const [filter, setFilter] = useState(false);
    const [orderList, setOrderList] = useState({});
    const [tableLoader, setTableLoader] = useState(true);
    const [filterLoader, setFilterLoader] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);

    const [selectAll, setSelectAll] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState(new Set());
    const [rowSelectFalse, setRowSelectFalse] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);


    _forceNavigate(() => {
        //eslint-disable-next-line
        if (location.pathname == "/orders") {
            navigate(-2)
        }
    });

    useEffect(() => {
        _getFilterData();
        _getSummaryData();
    }, []);

    useEffect(() => {
        if (filterEnabled === true) {
            setSelectedPaymentStatus(selectedPayment);
            setSelectedOrderType(selectedOrder);
            setSelectedOrderStatus(selectedStatus);
            setSelectedMilestone(milestone);
            setAmountFrom(amtFrom)
            setAmountTo(amtTo)
            setFromDate(from)
            setEndDate(end);
            setSearch(searchValue)
        }
        // eslint-disable-next-line
    }, [filterEnabled])

    useEffect(() => {
        if (filterLoader === false) {
            if (location.state !== null) {
                if (location.state.selectedPaymentStatus.value !== null || location.state.selectedOrderStatus.value !== null ||
                    location.state.selectedOrderType.value !== null || location.state.selectedMilestone.value !== null || (location.state.fromDate !== "DD-MM-YYYY" && location.state.endDate !== "DD-MM-YYYY") ||
                    location.state.amountFrom !== "" || location.state.amountTo !== "") {
                    setFilterEnabled(true);
                }
                _getOrderList(location.state.search, location.state.selectedPaymentStatus, location.state.selectedOrderType, location.state.selectedOrderStatus,
                    location.state.selectedMilestone, location.state.endDate, location.state.fromDate, location.state.amountFrom, location.state.amountTo, location.state.page)
            }
        }
    }, [location, filterLoader]);



    useEffect(() => {
        if (filterLoader === false) {
            if (location.state === null) {
                _getOrderList(search, paymentStatusOptions[0], orderOptions[0], orderStatusOptions[0], mileStoneOptions[0], endDate, fromDate, amountFrom, amountTo, pageNumber);
            }
        }
        // eslint-disable-next-line
    }, [filterLoader])

    useEffect(() => {
        if (filter === true) {
            _validate();
        }
        // eslint-disable-next-line
    }, [filter]);

    // for deselecting header checkbox in the table
    useEffect(() => {
        if (rowSelectFalse === true) {
            setSelectAll(false);
        }
    }, [rowSelectFalse]);

    useEffect(() => {
        if (selectAll === true) {
            const order_id_set = new Set(orderList.orders.map((item) => item.order_id));
            setSelectedOrders((prevSet) => new Set([...prevSet, ...order_id_set]));
            setRowSelectFalse(false);
        }
        else {
            if (rowSelectFalse === false) {
                setSelectedOrders(new Set());
            }
        }
        //eslint-disable-next-line
    }, [selectAll, orderList])

    function _handleRowCheckBox(row, event) {
        // console.log("Row", row);
        event.stopPropagation();
        // console.log("before", selectedOrders)
        if (selectedOrders.has(row.order_id)) {
            setSelectedOrders((prevOrderIds) => {
                const new_order_ids = new Set(prevOrderIds);
                new_order_ids.delete(row.order_id);
                return new_order_ids;
            });
        }
        else {
            setSelectedOrders((prevOrderIds) => new Set([...prevOrderIds, row.order_id]));
        }
        setRowSelectFalse(true);
    }

    function _handleSelectAll(event) {
        event.stopPropagation();
        setSelectAll(!selectAll)
    }


    function _handleTooltip(event, status, reason) {
        setShowTooltip(status);
        if (status) {
            const boundingRect = event.target.getBoundingClientRect();
            const tooltipX = boundingRect.left + (boundingRect.width / 2);
            const tooltipY = boundingRect.top + boundingRect.height + window.scrollY;
            setTooltipPosition({ x: tooltipX, y: tooltipY });
            setTooltipContent(reason)
        }
    }

    function _handleNavigate(row) {
        navigate("/orders", {
            state: _filterData(row)
        });
        setTimeout(() => {
            navigate("detail", {
                state: _filterData(row)
            });
        }, 0);
    }

    function _handleAmount(input_value, type) {
        switch (type) {
            case 1:
                setAmountFrom(input_value)
                break;
            case 2:
                setAmountTo(input_value)
                break;
            default:
                break;
        }
    }

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _getOrderList("", selectedPayment, selectedOrder, selectedStatus, selectedMilestone, end, from, amtFrom, amtTo, 1)
        }
    }


    function _handleOrderType(search_value) {
        setSelectedOrderType(search_value)
    }

    function _handleMilestone(search_value) {
        setSelectedMilestone(search_value)
    }

    function _handleOrderStatus(search_value) {
        setSelectedOrderStatus(search_value)
    }

    function _handlePaymentStatus(search_value) {
        setSelectedPaymentStatus(search_value)
    }

    function _handleToggleFilter() {
        setFilterEnabled(!filterEnabled)
    }

    function _handleDate(date_range) {
        const start_date = _formatDate(date_range.from)
        const end_date = _formatDate(date_range.to);

        setFromDate(start_date);
        setEndDate(end_date);
    }

    function _filterData(row) {
        let filter_data = ""
        filter_data = {
            id: row.order_id,
            page: pageNumber,
            selectedPaymentStatus: selectedPayment,
            selectedOrderStatus: selectedStatus,
            selectedOrderType: selectedOrder,
            selectedMilestone: milestone,
            fromDate: from,
            endDate: end,
            amountFrom: amtFrom,
            amountTo: amtTo,
            search: searchValue
        }
        return filter_data
    }

    const ORDER_COLUMNS = [
        {
            name: <div className={`d-flex gap-12px align-items-center`}>
                {/* Add your custom image or icon here */}
                <Icon icon={selectAll === true ? "checked" : "unchecked"}
                    size={18}
                    className="cursor-pointer"
                    onClick={(event) => _handleSelectAll(event)} />

                <p className="mb-0" >ORDER ID</p>
            </div>,
            selector: row => row.order_id,
            width: '170px',
            sortable: true,
            cell: row =>
                <div className="d-flex align-items-center gap-12px" onClick={() => _handleNavigate(row)}>

                    <Icon icon={selectedOrders.has(row['order_id']) ? "checked" : "unchecked"}
                        size={18}
                        onClick={(event) => _handleRowCheckBox(row, event)} />


                    <p className="color-dark_charcoal e-montserrat-medium e-font-14 e-line-height-24 mb-0 mt-0">{row.order_id === null ? "-" : row.order_id}</p>
                </div>
        },

        {
            name: "ORDER NO.",
            selector: row => row.order_no === null ? "-" : row.order_no,
            width: "160px",
        },
        {
            name: "Customer name",
            selector: row => <LinkButton text={row.customer_name} handleClick={() => navigate('/customer/detail', {
                state: {
                    id: row.customer_id,
                    tab_index: 2
                }
            })} />,
            width: "160px",
        },

        {
            name: "FP ID",
            selector: row => row.fp_id === null ? "-" : row.fp_id,
            width: "100px",
        },
        {
            name: "Ordered on",
            selector: row => row.order_date === null ? "-" : row.order_date,
            width: "140px",
            sortable: true,
        },
        {
            name: "Milestone",
            selector: row => row.investment_name === null ? "-" : row.investment_name,
            width: "160px",
        },
        {
            name: "Basket/ Fund",
            selector: row => row.investment_type_text === null ? "-" : row.investment_type_text,
            width: "160px",
        },
        {
            name: "Order type",
            width: "110px",
            selector: row => row.type === null ? "-" : row.type
        },

        {
            name: "Units",
            width: "110px",
            selector: row => row.units === null ? "-" : row.units
        },
        {
            name: "Amount",
            selector: row => row.amount_text === null ? "-" : row.amount_text,
            sortable: true,
        },
        {
            name: "Order status",
            width: "140px",
            cell: row => row.order_status === null ? "-" : <Badge status={row.order_status}
                color={row.order_status === "Successful" ? Config.BADGE_COLORS.green :
                    row.order_status === "Pending" ? Config.BADGE_COLORS.orange :
                        row.order_status === "Partially success" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.red} onClick={() => _handleNavigate(row)} />
        },
        {
            name: "Payment status",
            width: "140px",
            cell: row => row.payment_status === null || row.type === "Withdrawal" || row.type === "SWP" || row.type === "Switch" ? "-" : <div className="d-flex position-relative">
                <Badge status={row.payment_status}
                    color={row.payment_status === "Successful" ? Config.BADGE_COLORS.green :
                        row.payment_status === "Pending" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.red} onClick={() => _handleNavigate(row)} />


                {
                    row.payment_status === "Failed" &&
                    <div id={`tooltip${row.id}`} onMouseEnter={(event) => _handleTooltip(event, row.order_id, row.failure_reason)}
                        onMouseLeave={() => _handleTooltip(null, null)} >
                        <Icon icon="circle-information" size="16px"
                            className="margin-2px-left cursor-pointer"
                        />

                    </div>
                }
            </div>
        },
        {
            name: "Updated on",
            selector: row => row.updated_on === null ? "-" : row.updated_on,
            width: "140px",
            sortable: true,
        },

    ]

    const _validate = () => {
        let valid = true;
        let error = "";


        // Validate AUM range
        if (amountFrom !== "" && (isNaN(amountFrom) || parseFloat(amountFrom) <= 0)) {
            error = "Enter a valid from amount greater than 0";
            valid = false;
        } else if (amountTo !== "" && (isNaN(amountTo) || parseFloat(amountTo) <= 0 || parseFloat(amountTo) <= parseFloat(amountFrom))) {
            error = "Enter a valid to amount greater than from amount and greater than 0";
            valid = false;
        }

        if (valid === true) {
            setFilterEnabled(false);
            _getOrderList(search, selectedPayment, selectedOrder, selectedStatus, milestone, end, from, amtFrom, amountTo, pageNumber);
        }
        else {
            toast.dismiss();
            toast.error(error, {
                type: "error",
            });
            setFilter(false);
        }
    }

    const _handleFilter = () => {
        setFilter(true);
        setSelectedPayment(selectedPaymentStatus);
        setSelectedOrder(selectedOrderType);
        setSelectedStatus(selectedOrderStatus);
        setMilestone(selectedMilestone);
        setAmtFrom(amountFrom)
        setAmtTo(amtTo)
        setFrom(fromDate)
        setEnd(endDate);
        // setTableLoader(true);
    }

    // API - get summary data
    const _getSummaryData = () => {
        const url = "/orders/summary";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
                setScreenLoader(false);
            }
        });
    }

    // API - get filter data
    const _getFilterData = () => {
        _getOrderFilterData().then((response) => {
            setOrderOptions(response.data.order_types);
            // setSelectedOrderType(response.data.order_types[0]);
            setPaymentStatusOptions(response.data.payment_status);
            // setSelectedPaymentStatus(response.data.payment_status[0]);
            setOrderStatusOptions(response.data.order_status);
            // setSelectedOrderStatus(response.data.order_status[0]);
            setMileStoneOptions(response.data.milestone);
            // setSelectedMilestone(response.data.milestone[0]);
            setFilterLoader(false)
        });
    }

    const _handleClear = () => {
        setSelectedPaymentStatus(paymentStatusOptions[0]);
        setSelectedOrderType(orderOptions[0]);
        setSelectedOrderStatus(orderStatusOptions[0]);
        setSelectedMilestone(mileStoneOptions[0]);
        setEndDate("DD-MM-YYYY");
        setFromDate("DD-MM-YYYY");
        setAmountFrom("")
        setAmountTo("")
        setPageNumber(1);
    }

    const _handlePageChange = (page) => {
        setPageNumber(page);
        _getOrderList(search, selectedPaymentStatus, selectedOrderType, selectedOrderStatus, selectedMilestone, endDate, fromDate, amountFrom, amountTo, page);
    }

    // API - get user list
    const _getOrderList = (search_value = "", payment_type = null, order_type = null, order_status = null, milestone = null, to_date = null, from_date = null, amount_from = null, amount_to = null, page = 1) => {
        setSearch(search_value);
        setSelectedPaymentStatus(payment_type);
        setSelectedOrderType(order_type);
        setSelectedOrderStatus(order_status);
        setSelectedMilestone(milestone);
        setEndDate(to_date);
        setFromDate(from_date);
        setAmountFrom(amount_from === null ? "" : amount_from)
        setAmountTo(amount_to === null ? "" : amount_to);
        setPageNumber(page);

        setSearchValue(search_value);
        setSelectedPayment(payment_type);
        setSelectedOrder(order_type);
        setSelectedStatus(order_status);
        setMilestone(milestone);
        setEnd(to_date);
        setFrom(from_date);
        setAmtFrom(amount_from === null ? "" : amount_from)
        setAmtTo(amount_to === null ? "" : amount_to);
        setPageNumber(page);

        setTableLoader(true);
        const url = "/orders/list";
        const request = {
            payment_status: payment_type !== null ? payment_type.value : null,
            order_status: order_status !== null ? order_status.value : null,
            type: order_type !== null ? order_type.value : null,
            milestone: milestone !== null ? milestone.value : null,
            from_date: from_date === "DD-MM-YYYY" ? null : from_date,
            to_date: to_date === "DD-MM-YYYY" ? null : to_date,
            amount_from: amount_from === "" ? null : amount_from,
            amount_to: amount_to === "" ? null : amount_to,
            page_num: page,
            page_size: 12,
            query: search_value.trim(),
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setOrderList(response.data);

            } else {
                setOrderList({});
            }
            if (payment_type.value !== null || order_status.value !== null || order_type.value !== null || milestone.value !== null || amount_from.length !== 0 || amount_to.length !== 0 || (from_date !== "DD-MM-YYYY" && to_date !== "DD-MM-YYYY")) {
                setFilterApplied(true)
            } else {
                setFilterApplied(false);
            }
            setTableLoader(false);
            setFilter(false);
        })
    }

    // API - list download
    const _downloadList = () => {
        setDownloadLoader(true);
        const selected_ids = new Set(selectedOrders);
        const selected_array = Array.from(selected_ids);
        const url = "/orders/list-download";
        const request = {
            "order_ids": selected_array,
            "filter_data": {
                payment_status: selectedPayment.value,
                order_status: selectedStatus.value,
                type: selectedOrder.value,
                milestone: selectedMilestone.value,
                from_date: from === "DD-MM-YYYY" ? null : from,
                to_date: end === "DD-MM-YYYY" ? null : end,
                amount_from: amtFrom === "" ? null : amtFrom,
                amount_to: amtTo === "" ? null : amtTo,
                query: search,
            }
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("List downloaded successfully", {
                    type: "success"
                });
                window.open(response.data.url, "_blank");
                setSelectedOrders(new Set());
                setSelectAll(false);
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setDownloadLoader(false);
        });
    }

    return (
        <Fragment>
            <div className="e-bg-light-lavender pt-3 padding-52px-lr">
                <CustomTitle title="Order summary" type={1} />
            </div>
            {
                screenLoader === true ?
                    <div className="min-h-100vh d-flex align-items-center justify-content-center">
                        <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                            alt={"loader"}
                            width={60}
                            height={60}
                        />
                    </div>
                    :
                    <div className="e-body-wrapper">
                        <div className="row row-gap-16px">
                            <div className="col">
                                <InfoCard type={1}
                                    title="Total orders"
                                    data={summaryData.total_orders}
                                    count={summaryData.total_orders_amount_text}
                                    countText={summaryData.total_orders_amount} />
                            </div>
                            <div className="col">
                                <InfoCard type={1}
                                    title="Completed orders"
                                    data={summaryData.completed_orders}
                                    count={summaryData.completed_orders_amount_text}
                                    countText={summaryData.completed_orders_amount}
                                />
                            </div>
                            <div className="col">
                                <InfoCard type={1}
                                    title="Processing orders"
                                    data={summaryData.processing_orders}
                                    count={summaryData.processing_orders_amount_text}
                                    countText={summaryData.processing_orders_amount} />
                            </div>
                            <div className="col">
                                <InfoCard type={1}
                                    title="Failed orders"
                                    data={summaryData.failed_orders}
                                    count={summaryData.failed_orders_amount_text}
                                    countText={summaryData.failed_orders_amount} />
                            </div>
                            <div className="col">
                                <InfoCard type={1}
                                    className="w-max-content"
                                    title="Payment Pending orders"
                                    data={summaryData.payment_pending_orders}
                                    count={summaryData.payment_pending_orders_amount_text}
                                    countText={summaryData.payment_pending_orders_amount} />
                            </div>
                        </div>
                        <div className="bg-white border-radius-24px padding-32px-tb mt-4">
                            <div className="row d-flex align-items-center mb-4">
                                <div className="col-lg-4 col-md-12 col-7">
                                    <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-0 padding-32px-lr">
                                        Orders
                                    </h4>
                                </div>
                                <div className="col-lg-3 col-md-5 col-5 text-end  pb-md-0 pb-3">
                                    <DownloadButton
                                        // disabled={selectedOrders.size === 0 ? true : false}
                                        loader={downloadLoader}
                                        onPress={_downloadList} />
                                </div>
                                <div className="col-lg-4 col-md-6 col-10 ps-md-0 ps-5">
                                    {/* const _getOrderList = (search_value = "", payment_type = null, order_type = null, order_status = null, milestone = null, to_date = null, from_date = null, amount_from = null, amount_to = null, page = 1) => { */}

                                    <SearchInput value={search}
                                        placeholder="Search with  order No."
                                        clear={() => {
                                            _getOrderList("", selectedPayment, selectedOrder, selectedStatus, selectedMilestone, end, from, amtFrom, amtTo, 1);
                                        }}
                                        valueChange={_handleSearch}
                                        handleSearch={(value) => _getOrderList(search, selectedPayment, selectedOrder, selectedStatus, selectedMilestone, end, from, amtFrom, amtTo, 1)} />
                                </div>
                                <div className="col-lg-1 col-md-1 col-2 ">
                                    <div className="position-relative w-max-content">
                                        <Icon icon={filterEnabled ? "filter-active" : "filter-inactive"}
                                            size={42}
                                            className={`${filterLoader === true ? "disabled" : "cursor-pointer"}`}
                                            onClick={_handleToggleFilter} />
                                        {
                                            filterApplied === true &&
                                            <span className={`position-absolute top-0 end-0 rounded-pill e-font-10 e-line-height-12 color-white e-montserrat-bold w-10px h-10px padding-1px-top d-flex align-items-center justify-content-center ${filterEnabled === true ? "e-bg-light-gray" : "e-bg-crimpson-red"}`}>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>

                            {filterEnabled && <div className="e-bg-light-gray py-3 padding-32px-lr margin-10px-bottom">
                                <div className="row">


                                    <div className="col-lg-2 col-md-6">
                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                            Payment status
                                        </label>
                                        <CustomSelect value={selectedPaymentStatus}
                                            options={paymentStatusOptions}
                                            selectChange={_handlePaymentStatus} />
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                            Order status
                                        </label>
                                        <CustomSelect value={selectedOrderStatus}
                                            options={orderStatusOptions}
                                            selectChange={_handleOrderStatus} />
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                            Order type
                                        </label>
                                        <CustomSelect value={selectedOrderType}
                                            options={orderOptions}
                                            selectChange={_handleOrderType} />
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                            Milestones
                                        </label>
                                        <CustomSelect value={selectedMilestone}
                                            options={mileStoneOptions}
                                            selectChange={_handleMilestone} />
                                    </div>
                                </div>
                                <div className="row pt-3">
                                    <div className="col-lg-4 col-md-6">

                                        <CustomCalendar
                                            disabledDays={disabledDays}
                                            label="Ordered on"
                                            mode="range"
                                            start={fromDate}
                                            end={endDate}
                                            selectDate={_handleDate} />
                                    </div>
                                    <div className="col-lg-3 col-md-6 pe-0">
                                        <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">Amount</label>
                                        <div className="row">
                                            <div className="col-5 pe-0">
                                                <div className="d-flex align-items-center">
                                                    <span className="e-montserrat-regular e-font-12 e-line-height-14 color-dark-charcoal margin-2px-right">From</span>
                                                    <CustomTextInput type="number"
                                                        placeholder={1}
                                                        value={amountFrom}
                                                        valueChange={(input_value) => _handleAmount(input_value, 1)} />
                                                </div>
                                            </div>
                                            <div className="col-5 ps-1">
                                                <div className="d-flex align-items-center">
                                                    <span className="e-montserrat-regular e-font-12 e-line-height-14 color-dark-charcoal margin-2px-right">To</span>
                                                    <CustomTextInput type="number"
                                                        placeholder={100}
                                                        value={amountTo}
                                                        valueChange={(input_value) => _handleAmount(input_value, 2)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* apply filter */}
                                <div className=" margin-32px-top d-flex gap-24px">

                                    <PrimaryButton label="Filter now"
                                        className="padding-14px-tb padding-38px-lr"
                                        handleClick={_handleFilter} />


                                    <SecondaryButton label="Clear"
                                        className="padding-14px-tb padding-38px-lr"
                                        handleClick={_handleClear} />

                                </div>
                            </div>}

                            <div className="position-relative">
                                <div className="d-flex justify-content-end e-montserrat-semi-bold e-font-12 e-line-height-14 align-items-center margin-12px-bottom  padding-32px-lr ">
                                    <img src={require("../../../Assets/Images/DataTable/scroll.gif")}
                                        alt="scroll"
                                        width={24}
                                        height={24}
                                        className="me-1 rotate-90deg" />
                                    Scroll horizontally for view more  information
                                </div>
                                {
                                    tableLoader === true ?
                                        <div className="h-80vh d-flex align-items-center justify-content-center">
                                            <img src={require("../../../Assets/Images/loader/page-loader.gif")}
                                                alt={"loader"}
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        :
                                        orderList.orders !== undefined && orderList.orders.length === 0 ?
                                            <EmptyScreen
                                                title="No orders found"
                                                image={require("../../../Assets/Images/loader/empty-funds.png")}
                                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                                            :
                                            <Fragment>
                                                <DataTableContainer data={orderList.orders}
                                                    columns={ORDER_COLUMNS}
                                                    customStyles={TABLE_STYLE}
                                                    selectableRows={false}
                                                    paginationComponentOptions={{ noRowsPerPage: true }}
                                                    defaultSortFieldId={1}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    perPage={12}
                                                    onChangePage={_handlePageChange}
                                                    paginationTotalRows={orderList.total_count}
                                                    paginationDefaultPage={orderList.current_page}
                                                    selectRows={(allSelected, selectedCount, selectedRows) => {
                                                    }}
                                                    rowClick={_handleNavigate}
                                                />
                                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {orderList.record} of {orderList.total_count} entries</span>
                                            </Fragment>
                                }

                            </div>
                            {showTooltip !== null && <div
                                className="e-order-list-tooltip position-absolute right-84px w-280px"
                                style={{ top: tooltipPosition.y + 10 }}
                            >
                                {tooltipContent}
                            </div>}
                        </div>
                    </div>
            }
        </Fragment >
    )
}

export default OrdersListing;