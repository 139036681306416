/*
 *   File : order-list.js
 *   Author : https://evoqins.com
 *   Description : Customer order list
 *   Version : 1.0.0
*/


import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Custom components
import { DataTableContainer } from "../../../../Components/DataTable";
import { CustomSelect, SearchInput } from "../../../../Components/FormElements";
import { Icon } from "../../../../Components/Icon";
import { Badge } from "../../../../Components/Badge";
import { CustomCalendar, EmptyScreen } from "../../../../Components/Others";
import { PrimaryButton, SecondaryButton } from "../../../../Components/Buttons";

// Custom styles
import Colors from '../../../../Styles/color.module.scss';
import { _formatDate } from "../../../../Helper/helper";

// Services
import APIService from "../../../../Services/api-service";
import { _getOrderFilterData } from "../../../../Helper/api";
import Config from "../../../../Helper/config";

// Table custom style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
        },
    },

    headRow: {
        style: {
            borderBottomColor: 'transparent',
            paddingLeft: "32px",
            color: Colors.dark_gray,
            fontFamily: "Montserrat-Bold",
            fontSize: "14px",
            lineHeight: "18px",
            paddingTop: "0px",
            paddingBottom: '0px',
            minHeight: "32px"
        }
    },
    rows: {
        style: {
            paddingLeft: "32px",
            borderBottom: 'none !important',
            cursor: 'pointer',
            '&:nth-child(odd)': {
                backgroundColor: '#0D0D0D05',

            },
            '&:hover': {
                backgroundColor: Colors.lavender_mist
            },

        },
    },
    cells: {
        style: {
            paddingTop: '16px',
            paddingBottom: '16px',
            color: Colors.dark_charcoal,
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "0px",
            userSelect: "none",
        }
    },
    headCells: {
        style: {
            paddingLeft: "0px"
        }
    },
    pagination: {
        style: {
            paddingRight: "50px",
            paddingTop: "10px",
            borderTop: "none"
        }
    }
};

const OrdersList = () => {

    const disabledDays = { after: new Date() };
    const location = useLocation();
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [filterEnabled, setFilterEnabled] = useState(false);
    const [orderOptions, setOrderOptions] = useState([]);
    const [selectedOrderType, setSelectedOrderType] = useState({});
    const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
    const [selectedPaymentStatus, setSelectedPaymentStatus] = useState({});
    const [orderStatusOptions, setOrderStatusOptions] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState({});
    const [mileStoneOptions, setMileStoneOptions] = useState([]);
    const [selectedMilestone, setSelectedMilestone] = useState({});
    const [fromDate, setFromDate] = useState("DD-MM-YYYY");
    const [endDate, setEndDate] = useState("DD-MM-YYYY");
    const [showTooltip, setShowTooltip] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState(null);
    const [tooltipContent, setTooltipContent] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [orderList, setOrderList] = useState({});
    const [screenLoader, setScreenLoader] = useState(true);
    const [filterLoader, setFilterLoader] = useState(true);
    const [filter, setFilter] = useState(false);

    // const [selectAll, setSelectAll] = useState(false);
    // const [selectedOrders, setSelectedOrders] = useState(new Set());
    // const [rowSelectFalse, setRowSelectFalse] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);


    const [allFilterData, setAllFilterData] = useState({
        page_num: 1,
        from_date: "DD-MM-YYYY",
        to_date: "DD-MM-YYYY",
        order_type: null,
        goal_type: null,
        payment_status: null,
        order_status: null,
        query: "",
    })


    const _updateFilterData = (property, value) => {
        setAllFilterData(prevState => ({
            ...prevState,
            [property]: value
        }));
    };


    useEffect(() => {
        _getFilterData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (filterEnabled === true) {
            setFromDate(allFilterData.from_date);
            setEndDate(allFilterData.to_date);
            setSelectedOrderType(allFilterData.order_type);
            setSelectedMilestone(allFilterData.goal_type);
            setSelectedPaymentStatus(allFilterData.payment_status);
            setSelectedOrderStatus(allFilterData.order_status);
        }
        // eslint-disable-next-line
    }, [filterEnabled]);

    useEffect(() => {
        _getOrderList();
        // eslint-disable-next-line
    }, [pageNumber]);

    useEffect(() => {
        if (filter === true) {
            _getOrderList();
            setFilterEnabled(false);
            setScreenLoader(true);
        }
        // eslint-disable-next-line
    }, [filter])

    function _handleSearch(search_value) {
        setSearch(search_value);
        if (search_value === "") {
            _getOrderList({ clearSearch: "" })
        }
    }

    // for deselecting header checkbox in the table
    // useEffect(() => {
    //     if (rowSelectFalse === true) {
    //         setSelectAll(false);
    //     }
    // }, [rowSelectFalse]);

    // useEffect(() => {
    //     if (selectAll === true) {
    //         const order_id_set = new Set(orderList.orders.map((item) => item.order_id));
    //         setSelectedOrders((prevSet) => new Set([...prevSet, ...order_id_set]));
    //         setRowSelectFalse(false);
    //     }
    //     else {
    //         if (rowSelectFalse === false) {
    //             setSelectedOrders(new Set());
    //         }
    //     }
    //     //eslint-disable-next-line
    // }, [selectAll, orderList])


    function _handleOrderType(search_value) {
        setSelectedOrderType(search_value)
    }

    function _handleGoal(search_value) {
        setSelectedMilestone(search_value)
    }


    // function _handleRowCheckBox(row, event) {
    //     // console.log("Row", row);
    //     event.stopPropagation();
    //     // console.log("before", selectedOrders)
    //     if (selectedOrders.has(row.order_id)) {
    //         setSelectedOrders((prevOrderIds) => {
    //             const new_order_ids = new Set(prevOrderIds);
    //             new_order_ids.delete(row.order_id);
    //             return new_order_ids;
    //         });
    //     }
    //     else {
    //         setSelectedOrders((prevOrderIds) => new Set([...prevOrderIds, row.order_id]));
    //     }
    //     setRowSelectFalse(true);
    // }

    // function _handleSelectAll(event) {
    //     event.stopPropagation();
    //     setSelectAll(!selectAll)
    // }

    function _handleOrderStatus(search_value) {
        setSelectedOrderStatus(search_value)
    }

    function _handlePaymentStatus(search_value) {
        setSelectedPaymentStatus(search_value)
    }

    function _handleTooltip(event, status, reason) {
        setShowTooltip(status);
        if (status) {
            const boundingRect = event.target.getBoundingClientRect();
            const tooltipX = boundingRect.left + (boundingRect.width / 2);
            const tooltipY = boundingRect.top + boundingRect.height + window.scrollY;
            setTooltipPosition({ x: tooltipX, y: tooltipY });
            setTooltipContent(reason)
        }
    }

    function _handleDate(date_range) {

        const formatted_start_date = _formatDate(date_range.from);
        const formatted_end_date = _formatDate(date_range.to);
        setFromDate(formatted_start_date);
        setEndDate(formatted_end_date);
    }

    function _handleToggleFilter() {
        setFilterEnabled(!filterEnabled)
    }

    function _handleNavigate(row) {
        navigate("/orders/detail", {
            state: {
                id: row.order_id,
                page: 1,
                selectedPaymentStatus: { label: "All", value: null },
                selectedOrderStatus: { label: "All", value: null },
                selectedOrderType: { label: "All", value: null },
                selectedMilestone: { label: "All", value: null },
                fromDate: "DD-MM-YYYY",
                endDate: "DD-MM-YYYY",
                amountFrom: "",
                amountTo: "",
                search: ""
            }
        })
    }

    const _handlePageChange = (page) => {
        setPageNumber(page);
        setScreenLoader(true);
        _updateFilterData("page_num", page);
    }

    const ORDER_COLUMNS = [
        {
            name: <div className={`w-fit-content d-flex gap-12px align-items-center`}>
                {/* Add your custom image or icon here */}
                {/* <Icon icon={selectAll === true ? "checked" : "unchecked"}
                    size={18}
                    className="cursor-pointer"
                    onClick={(event) => _handleSelectAll(event)} /> */}

                <p className="mb-0">ORDER ID</p>
            </div>,
            selector: row => row.order_id,
            width: '170px',
            cell: row =>
                <div className="d-flex align-items-center gap-12px" onClick={() => _handleNavigate(row)}>
                    {/* 
                    <Icon icon={selectedOrders.has(row['order_id']) ? "checked" : "unchecked"}
                        size={18}
                        onClick={(event) => _handleRowCheckBox(row, event)} /> */}


                    <p className="color-dark_charcoal e-montserrat-medium e-font-14 e-line-height-24 mb-0 mt-0">{row.order_id === null ? "-" : row.order_id}</p>
                </div>
        },
        {
            name: "ORDER NO.",
            selector: row => row.order_no === null ? "-" : row.order_no,
            width: "160px",
        },
        {
            name: "Invested in ",
            cell: row => row.name,
            width: "250px"
        },
        {
            name: "Type",
            width: "110px",
            cell: row => row.type
        },
        {
            name: "Ordered date",
            cell: row => row.order_date,
            width: "150px"
        },
        {
            name: "Amount",
            cell: row => row.amount_text,
            width: "120px"
        },
        {
            name: "Order status",
            width: "150px",
            cell: row => <Badge status={row.order_status}
                pendingClass="e-bg-golden-yellow"
                color={row.order_status === "Successful" ? Config.BADGE_COLORS.green :
                    row.order_status === "Pending" ? Config.BADGE_COLORS.orange :
                        row.order_status === "Partially success" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.red}
                onClick={() => _handleNavigate(row)} />
        },
        {
            name: "Payment status",
            width: "150px",
            cell: row => row.payment_status === null || row.type === "Withdrawal" || row.type === "SWP" || row.type === "Switch" ? "-" : <div className="d-flex position-relative">
                <Badge status={row.payment_status}
                    color={row.payment_status === "Successful" ? Config.BADGE_COLORS.green :
                        row.payment_status === "Pending" ? Config.BADGE_COLORS.orange :
                            Config.BADGE_COLORS.red} onClick={() => _handleNavigate(row)} />


                {
                    row.payment_status === "Failed" &&
                    <div id={`tooltip${row.id}`} onMouseEnter={(event) => _handleTooltip(event, row.order_id, row.failure_reason)}
                        onMouseLeave={() => _handleTooltip(null, null)} >
                        <Icon icon="circle-information" size="16px"
                            className="margin-2px-left cursor-pointer"
                        />

                    </div>
                }
            </div>
        },

    ]

    // API - non investment data
    const _getOrderList = ({ clearSearch = null } = {}) => {
        setScreenLoader(true);
        const url = "/user/list-order";
        const request = {
            customer_id: location.state.id,
            from_date: allFilterData.from_date === "DD-MM-YYYY" ? null : allFilterData.from_date,
            to_date: allFilterData.to_date === "DD-MM-YYYY" ? null : allFilterData.to_date,
            type: allFilterData.order_type === null ? null : allFilterData.order_type.value,
            payment_status: allFilterData.payment_status === null ? null : allFilterData.payment_status.value,
            order_status: allFilterData.order_status === null ? null : allFilterData.order_status.value,
            milestone: allFilterData.goal_type === null ? null : allFilterData.goal_type.value,
            page_num: allFilterData.page_num,
            page_size: 12,
            query: clearSearch !== null ? clearSearch : allFilterData.query,
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (clearSearch !== null) {
                    _updateFilterData("query", clearSearch);
                    setSearch(clearSearch);
                }
                if ((allFilterData.payment_status !== null && allFilterData.payment_status.value !== null) || (allFilterData.order_status !== null && allFilterData.order_status.value !== null) || (allFilterData.order_type !== null && allFilterData.order_type.value !== null) || (allFilterData.goal_type !== null && allFilterData.goal_type.value !== null) || (allFilterData.from_date !== "DD-MM-YYYY" && allFilterData.to_date !== "DD-MM-YYYY")) {
                    setFilterApplied(true);
                } else {
                    setFilterApplied(false);
                }
                setOrderList(response.data);
            }
            else {
                setOrderList({});
            }
            setScreenLoader(false);
            setFilter(false);
        })
    }

    // API - get filter data
    const _getFilterData = () => {
        _getOrderFilterData().then((response) => {
            setOrderOptions(response.data.order_types);
            setPaymentStatusOptions(response.data.payment_status);
            setOrderStatusOptions(response.data.order_status);
            setMileStoneOptions(response.data.milestone);
            _updateFilterData("order_type", response.data.order_types[0]);
            _updateFilterData("goal_type", response.data.payment_status[0]);
            _updateFilterData("payment_status", response.data.order_status[0]);
            _updateFilterData("order_status", response.data.milestone[0]);

            setFilterLoader(false)
        });
    }

    const _handleFilter = () => {
        setFilter(true);
        setPageNumber(1);
        _updateFilterData("page_num", 1);
        _updateFilterData("from_date", fromDate);
        _updateFilterData("to_date", endDate);
        _updateFilterData("order_type", selectedOrderType);
        _updateFilterData("goal_type", selectedMilestone);
        _updateFilterData("payment_status", selectedPaymentStatus);
        _updateFilterData("order_status", selectedOrderStatus);
    }

    const _handleClear = () => {
        setFromDate("DD-MM-YYYY");
        setEndDate("DD-MM-YYYY");
        setSelectedOrderType(orderOptions[0]);
        setSelectedMilestone(mileStoneOptions[0]);
        setSelectedPaymentStatus(paymentStatusOptions[0]);
        setSelectedOrderStatus(orderStatusOptions[0]);
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-24px padding-32px-tb">
                <div className="row d-flex align-items-center mb-4">
                    <div className="col-lg-8 col-md-6">
                        <h4 className="e-font-16 e-line-height-20 e-montserrat-semi-bold color-gunmetal-gray mb-0 padding-32px-lr">
                            Orders
                        </h4>
                    </div>
                    <div className="col-lg-3 col-md-5 col-10 ps-md-0 ps-5">
                        <SearchInput value={search}
                            clear={() => _getOrderList({ clearSearch: "" })}
                            placeholder="Search with  order ID"
                            valueChange={_handleSearch}
                            handleSearch={(value) => _getOrderList({ clearSearch: value })} />
                    </div>
                    <div className="col-lg-1 col-md-1 col-2">
                        <div className="position-relative w-max-content">
                            <Icon icon={filterEnabled ? "filter-active" : "filter-inactive"}
                                size={42}
                                className={`${filterLoader === true ? "disabled" : "cursor-pointer"}`}
                                onClick={_handleToggleFilter} />
                            {
                                filterApplied === true &&
                                <span className={`position-absolute top-0 end-0 rounded-pill e-font-10 e-line-height-12 color-white e-montserrat-bold w-10px h-10px padding-1px-top d-flex align-items-center justify-content-center ${filterEnabled === true ? "e-bg-light-gray" : "e-bg-crimpson-red"}`}>
                                </span>
                            }
                        </div>
                    </div>
                </div>

                {filterEnabled && <div className="e-bg-light-gray padding-12px-tb padding-32px-lr margin-10px-bottom">
                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <CustomCalendar
                                disabledDays={disabledDays}
                                label="Ordered on"
                                mode="range"
                                start={fromDate}
                                end={endDate}
                                selectDate={_handleDate} />
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                Order type
                            </label>
                            <CustomSelect value={selectedOrderType}
                                options={orderOptions}
                                selectChange={_handleOrderType} />
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                Goal
                            </label>
                            <CustomSelect value={selectedMilestone}
                                options={mileStoneOptions}
                                selectChange={_handleGoal} />
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                Payment status
                            </label>
                            <CustomSelect value={selectedPaymentStatus}
                                options={paymentStatusOptions}
                                selectChange={_handlePaymentStatus} />
                        </div>
                        <div className="col-lg-2 col-md-3">
                            <label className="e-montserrat-medium e-font-14 e-line-height-18 color-dark-charcoal mb-1">
                                Order status
                            </label>
                            <CustomSelect value={selectedOrderStatus}
                                options={orderStatusOptions}
                                selectChange={_handleOrderStatus} />
                        </div>
                    </div>
                    {/* apply filter */}
                    <div className=" margin-32px-top d-flex gap-24px">

                        <PrimaryButton label="Apply"
                            className="padding-14px-tb padding-38px-lr"
                            handleClick={_handleFilter} />


                        <SecondaryButton label="Clear"
                            className="padding-14px-tb padding-38px-lr"
                            handleClick={_handleClear} />

                    </div>
                </div>}
                {
                    screenLoader === true ?
                        <div className="h-80vh d-flex align-items-center justify-content-center">
                            <img src={require("../../../../Assets/Images/loader/page-loader.gif")}
                                alt={"loader"}
                                width={60}
                                height={60}
                            />
                        </div> :

                        Object.keys(orderList).length === 0 || orderList.orders.length === 0 ?
                            <EmptyScreen className="my-5"
                                title="No orders found"
                                image={require("../../../../Assets/Images/loader/empty-funds.png")}
                                description="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s" />
                            :
                            <div className="position-relative">


                                <DataTableContainer data={orderList.orders}
                                    columns={ORDER_COLUMNS}
                                    customStyles={TABLE_STYLE}
                                    selectableRows={false}
                                    paginationComponentOptions={{ noRowsPerPage: true }}
                                    pagination={true}
                                    paginationServer={true}
                                    perPage={12}
                                    onChangePage={_handlePageChange}
                                    paginationTotalRows={orderList.total_count}
                                    paginationDefaultPage={orderList.current_page}
                                    rowClick={_handleNavigate} />


                                <span className="e-montserrat-regular e-font-14 e-line-height-18 padding-24px-lr position-absolute color-jett-black bottom-10px">Showing {orderList.record} of {orderList.total_count} entries</span>

                            </div>
                }
                {showTooltip !== null && <span
                    className="e-order-list-tooltip position-absolute right-84px w-max-content"
                    style={{ top: tooltipPosition.y + 10 }}
                >
                    {tooltipContent}
                </span>}
            </div>
        </Fragment>
    )
}

export default OrdersList;